import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from '../components/Navbar';
import { Auth } from 'aws-amplify';
import '../styles/Tables.css';
import '../styles/Loading.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate,faTable, faTrash } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx'; 


function Performances() {
  const columnsList = ["Kilit","Not","Hasta ID","Tarih","Acil Hekimi Muayene","Kan Alma","Acil-Görüntüleme Transfer","BT Kabul","Görüntüleme-Acil Transfer","Nörolog Muayene",
  "Trombolik Tedavisi","Anjiyografi Kabul","İnme Merkezine Yatış"];
  const station_list = ["2-Acil Hekimi Muayene",  "3-Kan Alma",  "4-Acil - Görüntüleme Transfer",  "5-BT Kabul",  "6-Görüntüleme - Acil Transfer",  "7-Nörolog Muayene",  "8-Trombolitik Tedavisi",  "9-Anjiyografi Kabul",  "10-İnme Merkezine Yatış"];

  const [patientData, setPatientData] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  var lockedPatientsList = {};
  const [authStatus, setAuthStatus] = useState(''); 
  const [dashboardUserName, setDashboardUserName] = useState(localStorage.getItem('username'));
  const [userData, setUserData] = useState([]);
  let authenticated = localStorage.getItem('authenticated')?true:false; // New state for authentication
  const [username, setUsername] = useState([]);
  const [password, setPassword] = useState([]);
  const [hospitalName, setHospitalName] = useState(dashboardUserName === "admin1" ? "Kartal Dr. Lütfi Kırdar Ş.H." : 
  dashboardUserName === "admin2" ? "İSÜ Liv Hospital Bahçeşehir" :
  dashboardUserName === "admin3" && "Başakşehir Çam ve Sakura Ş.H.");



  //Threshold values for stations
  const [acilHekimiTH, setAcilHekimiTH] = useState(10*60);
  const [kanAlmaTH, setKanAlmaTH] = useState(40*60);
  const [acilGörüntülemeTH, setAcilGörüntülemeTH] = useState(20*60);
  const [görüntülemeAcilTH, setGörüntülemeAcilTH] = useState(30*60);
  const [btKabulTH, setBtKabulTH] = useState(25*60);
  const [trombolikTH, setTrombolikTH] = useState(60*60);
  const [nörologMuayeneTH, setnörologMuayeneTH] = useState(45*60);

  useEffect(() => {
    fetchData(); // Fetch data from your APIs
  }, []);

  const filterFetcedData = (data) => {
    let filtered_data = [];

    data.forEach(element => {
      if(element.hospital.S === hospitalName){
        filtered_data.push(element);
      }
    });
    return filtered_data;
  }

  async function fetchData() {
    try {
      const patientDataResponse = await axios.get('https://server.edna.com.tr/api/patientData');
      const lockedPatientsResponse = await axios.get('https://server.edna.com.tr/api/patientInfo');
      const users = await axios.get('https://server.edna.com.tr/api/userData');
      const notes = (await axios.get('https://server.edna.com.tr/api/notes')).data.notes;
      

      for (const patient of lockedPatientsResponse.data) {
        lockedPatientsList[patient.patient_id.S] = patient.isClosed.BOOL;
      }
      let item = [];
      notes.forEach((note)=>{
        const note_item = note.notes.S;
        const patient_id = note.patient_id.S;
        item[patient_id] = note_item;

      })
      const patientData = patientDataResponse.data;
      dashboardUserName != "superadmin" ? setPatientData(filterFetcedData(patientDataResponse.data)) : setPatientData(patientData);

      setUserData(users.data);
      setDataFetched(true); // Mark data as fetched
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  if (!dataFetched) {
    return (
      <body className='loading-body'>
        <div className="loading-container">
          <div className="loading-spinner"></div>
          <p>Yükleniyor...</p>
        </div>
      </body>

  ); // Show loading message or spinner until data is fetched
  }




  // Function to convert time string to seconds
  function timeToSeconds(timeString) {
   
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }
  
  const setUserswStations = () => {
    const newData = {};
    for (let i = 0; i < patientData.length; i++) {
      const user_id = patientData[i]?.user_id?.S;
      if (user_id) {
        newData[user_id] = {
        
          "2-Acil Hekimi Muayene": { red: 0, green: 0 },
          "3-Kan Alma":  { red: 0, green: 0 },
          "4-Acil - Görüntüleme Transfer":  { red: 0, green: 0 },
          "5-BT Kabul":  { red: 0, green: 0 },
          "6-Görüntüleme - Acil Transfer": { red: 0, green: 0 },
          "7-Nörolog Muayene":  { red: 0, green: 0 },
          "8-Trombolitik Tedavisi":  { red: 0, green: 0 },
          "9-Anjiyografi Kabul":  { red: 0, green: 0 },
          "10-İnme Merkezine Yatış":  { red: 0, green: 0 },
        };
      }
    }
    return newData;
  };

  const updateUserwStationData = (data, user_id, stationName, color) => {
    if (data?.[user_id]?.[stationName] && color === "red") {
      data[user_id][stationName].red += 1;
    }
    else if (data?.[user_id]?.[stationName] && color === "green") {
      data[user_id][stationName].green += 1;
    }
  };

function setUserwStationsList(){
    let userList = setUserswStations();
    return userList;
  }

  let userListwStations = setUserwStationsList();

  const setUsers = () => {
    const newData = {};
    for (let i = 0; i < patientData.length; i++) {
      const user_id = patientData[i]?.user_id?.S;
      if (user_id) {
        newData[user_id] = {
        
                red: 0, green: 0

        };
      }
    }
    return newData;
  };

  const updateUserData = (data, user_id, color) => {
    if (data?.[user_id] && color === "red") {
      data[user_id].red += 1;
    }
    else if (data?.[user_id] && color === "green") {
      data[user_id].green += 1;
    }
  };

function setUserList(){
    let userList = setUsers();
    return userList;
  }

  let userList = setUserList();

  function calculateTimePassForPatient(patientData) {
    // Threshold values for stations

    const timePasses = [];
    const colors = [];
    const users = [];
    const date = patientData[0]?.date?.S;


    // Sort the data based on time values
    patientData = patientData.sort((a, b) => {
      const timeA = Number(a.station.S.slice(0,a.station.S.indexOf("-")));
      const timeB = Number(b.station.S.slice(0,b.station.S.indexOf("-")));
      
      // Use numerical comparison instead of localeCompare
      return timeA - timeB;
    });

    for (let k = 1; k < patientData.length ; k++) {
      var A;
      var K;
      var AGT;
      var BT;
      var GAT;
      var N;
      var TT;
      var AK;
      var IMY;

      var prevStation1 = patientData[k-1]?.station?.S;
      var nextStation1 = patientData[k]?.station?.S;

      let indexNext = Number(nextStation1.slice(0, nextStation1.indexOf("-")));
      let indexPrev = Number(prevStation1.slice(0, prevStation1.indexOf("-")));


      const t0 = timeToSeconds(patientData[0]?.time?.S);
      const nextTime = timeToSeconds(patientData[k]?.time?.S);
      let timePass = nextTime - t0;
      const dateString_before = patientData[0]?.date?.S
      const dateString_after = patientData[k]?.date?.S

      const parts_before = dateString_before.split("-");
      const day_before = parseInt(parts_before[0]);
      const month_before = parseInt(parts_before[1]); // Subtract 1 from the month as months are zero-based (0-11)
      const year_before = parseInt(parts_before[2]); // Assuming 'yy' represents a two-digit year (e.g., '23' -> 2023)

      const parts_after = dateString_after.split("-");
      const day_after = parseInt(parts_after[0]);
      const month_after = parseInt(parts_after[1]); // Subtract 1 from the month as months are zero-based (0-11)
      const year_after = parseInt(parts_after[2]); // Assuming 'yy' represents a two-digit year (e.g., '23' -> 2023)


      const day_diff = day_after - day_before;
      const month_diff = month_after - month_before;
      const year_diff = year_after - year_before;

      timePass = timePass + day_diff*24*60*60 + (day_before == 31 ? month_diff*31*24*60*60 : month_diff*30*24*60*60) + year_diff*365*30*24*60*60;

      
    if(indexNext !== indexPrev+1){
        for(let k=0; k<indexNext-indexPrev-1; k++){
          timePasses.push("Atlanmış");
        } 
      }
    if(nextStation1 === "2-Acil Hekimi Muayene"){
      A = timePass;
      timePasses.push(A);
      }
  
    else if(nextStation1 === "3-Kan Alma"){
      K = timePass;
      timePasses.push(K);
    }

    else if(nextStation1 === "4-Acil - Görüntüleme Transfer"){
      AGT = timePass;
      timePasses.push(AGT);
    }

    else if(nextStation1 === "5-BT Kabul"){
       BT = timePass;
       timePasses.push(BT);
    }

    else if(nextStation1 === "6-Görüntüleme - Acil Transfer"){
      GAT = timePass;
      timePasses.push(GAT);
    }

    else if(nextStation1 === "7-Nörolog Muayene"){
      N = timePass;
      timePasses.push(N);
    }

    else if(nextStation1 === "8-Trombolitik Tedavisi"){
      TT = timePass;
      timePasses.push(TT);
  }
    else if(nextStation1 === "9-Anjiyografi Kabul"){
      AK = timePass;
      timePasses.push(AK);
    }
    else if(nextStation1 === "10-İnme Merkezine Yatış"){
      IMY = timePass;
      timePasses.push(IMY);
    }
    }
    for (let i = 1; i < patientData.length; i++) {

      const prevStation = patientData[i-1]?.station?.S;
      const nextStation = patientData[i]?.station?.S;

      let indexNext = Number(nextStation.slice(0, nextStation.indexOf("-")));
      let indexPrev = Number(prevStation.slice(0, prevStation.indexOf("-")));

      if(indexNext !== indexPrev+1){
        for(let k=0; k<indexNext-indexPrev-1; k++){
          colors.push("grey");
          users.push("yok")
        } 
        
      }
      if(nextStation === "2-Acil Hekimi Muayene"){
        if(A > acilHekimiTH){
          colors.push("red");
        }
        else{
          if(K>AGT){
            if(BT > btKabulTH && (BT-AGT) < 5){
              colors.push("red");
            }
            else{
              colors.push("green");
            }
          }
          else{
            if(BT > btKabulTH && (BT-AGT) < 5 && K<AGT && (AGT - K < 5)){
              colors.push("red");
            }
            else{
              colors.push("green");
            }
          }
        }
      }
      
      else if(nextStation === "3-Kan Alma"){

          if(K<AGT && AGT > 0){
            if(!(A > 0)){
              colors.push("yellow");
            }
            else{
              if((K > kanAlmaTH) && ((K-A) > 20)){
                colors.push("red");
              }
              else{
                colors.push("green");
              }
           }
          }
          else{
            if(!(A > 0) || !(AGT > 0) || !(BT > 0) || !(GAT > 0)){
              colors.push("yellow");
            }
            else{
              if((K > kanAlmaTH) && ((K-AGT) > 15)){
                colors.push("red");
              }
              else{
                colors.push("green");
              }
          }
        }
      }

      else if(nextStation === "4-Acil - Görüntüleme Transfer"){
        if(!(A > 0)){
          colors.push("yellow");
        }
        else{
          if(K>AGT || !(K>0)){
            if((BT > btKabulTH) && (AGT > acilGörüntülemeTH) && (A <= acilHekimiTH)){
              colors.push("red")
            }
            else{
              colors.push("green");
            }
          }
          else{
            if((BT > btKabulTH) && (AGT > acilGörüntülemeTH) && (A <= acilHekimiTH) && (K<AGT) && (AGT - K <= 5)){
              colors.push("red")
            }
            else{
              colors.push("green");
            }
          }
        }
      }

      else if(nextStation === "5-BT Kabul"){
        if(!(A > 0) || !(AGT > 0)){
          colors.push("yellow");
        }
        else{
          if((BT > btKabulTH) &&  (AGT <= acilGörüntülemeTH) && (A <= acilHekimiTH) && (K>AGT)){
            colors.push("red");
          }
          else{
            colors.push("green");
          }
        }
      }

      else if(nextStation === "6-Görüntüleme - Acil Transfer"){
        if(!(A > 0) || !(AGT > 0) || !(BT > 0)){
          colors.push("yellow");
        }
        else{
          if(GAT > görüntülemeAcilTH){
            colors.push("red");
          }
          else{
            colors.push("green");
          }
        }
      }

      else if(nextStation === "7-Nörolog Muayene"){
        if(!(A > 0) || !(K > 0) || !(AGT > 0) || !(BT > 0) || !(GAT > 0)){
          colors.push("yellow");
        }
        else{
          if((N > nörologMuayeneTH) && (BT <= btKabulTH) && (GAT <= görüntülemeAcilTH) && (K <= kanAlmaTH)){
            colors.push("red");
          }
          else{
            colors.push("green");
          }
        }
      }

      else if(nextStation === "8-Trombolitik Tedavisi"){
        if(!(A > 0) || !(K > 0) || !(AGT > 0) || !(BT > 0) || !(GAT > 0) || !(N > 0)){
          colors.push("yellow");
        }
        else{
          if((TT > trombolikTH) && (N <= nörologMuayeneTH) && (BT <= btKabulTH) && (K <= kanAlmaTH)){
            colors.push("red");
          }
          else{
            colors.push("green");
          }
        }
      }

      else if(nextStation === "9-Anjiyografi Kabul"){
          colors.push("white");
      }

      else if(nextStation === "10-İnme Merkezine Yatış"){
          colors.push("white");
        
      }
      
      users[patientData[i-1]?.user_id?.S] = colors[colors.length-1] ;
      updateUserData(userList, patientData[i]?.user_id?.S, colors[colors.length-1]);
      updateUserwStationData(userListwStations, patientData[i]?.user_id?.S, nextStation, colors[colors.length-1]);
    }
    return [timePasses,colors,users,date];
  }
  
  function calculateTimePassForAllPatients(data) {
    const patients = {};
    data.forEach((entry) => {
      const patientId = entry.patient_id.S;
      if (!patients[patientId]) {
        patients[patientId] = [];
      }
      patients[patientId].push(entry);
    });

    const patientTimePasses = {};
    const colorsAll = JSON.parse(localStorage.getItem('colorsAll'));
    const allUsers = {};
    const dates = {};
    for (const patientId in patients) {
      const [timePass, colors, users, date] = calculateTimePassForPatient(patients[patientId]);
      patientTimePasses[patientId] = timePass;
      // Retrieving colorsAll on another page
        
        if (!(colorsAll.length > 0)) {
        //Do not use storedColorsAll in your new page or component
        colorsAll[patientId] = colors;
        }
      allUsers[patientId] = users;
      dates[patientId] = date;
    }
    return [patientTimePasses, colorsAll, allUsers, dates];
  }

  const calculatePerformances = (colors) => {
    let peroformanceArray = [];
    for (let employee in colors){
        const [red, green] = countColors(colors[employee]);
    }
  }

  const countColors = (colors_array) => {
    let red = 0;
    let green = 0;
    colors_array.forEach(color => {
        if(color === 'red'){
            red ++;
        }
           
        if(color === 'green'){
            green ++;
        }
           
    });
    return [red, green];
  }

  // Function to calculate time pass for all patients
  const [patientTimePasses,colorsAll,allUsers,dates] = calculateTimePassForAllPatients(patientData);
  calculatePerformances(colorsAll);
  // Function to convert seconds to time string (HH:mm:ss)


  const downloadExcel = () => {
    const wb = XLSX.utils.table_to_book(document.querySelectorAll('table')[1]); // Convert the table to a workbook
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' }); // Write the workbook to binary format
  
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' }); // Create a Blob from binary data
    const url = URL.createObjectURL(blob);
  
    const link = document.createElement('a');
    link.href = url;
    link.download = 'Çalışan Tablosu.xlsx'; // Set the desired file name
    link.click();
  
    URL.revokeObjectURL(url);
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };
  


  const logout = async () => {
    try {
      await Auth.signOut();
      authenticated = false;
      localStorage.removeItem('authenticated');
      setUsername('');
      setPassword('');
      setAuthStatus('');
      localStorage.setItem('username',"");
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };
  
  const authenticate = async (username, password) => {
    setAuthStatus('Giriş yapılıyor...');
    try {
      await Auth.signIn(username, password);
      authenticated = true;
      localStorage.setItem('authenticated', 'true'); // Store the authentication status in local storage
      localStorage.setItem('username', username);
      setDashboardUserName(localStorage.getItem('username'));
      window.location.reload(true);
    } catch (error) {
      console.error('Authentication failed:', error);
      authenticated = false;
      localStorage.removeItem('authenticated'); // Remove the authentication status from local storage
      setAuthStatus('Kullanıcı adı veya şifre hatalı'); // Set error message
    }
  };

  const handleDeleteUser = async (id) => {
    const confirmed = window.confirm('Bu kullanıcıyı silmek istediğine emin misin ?');
    if (!confirmed) {
      return; // User cancelled the delete action
    }
    try {
      axios.delete(`https://server.edna.com.tr/api/data/${id}`);
      axios.delete(`https://server.edna.com.tr/api/deleteCognitoUser/${id}`);
    }catch (e) {
      window.alert(
        "Failed to delete user"
      )
      console.log("Error",e);
    }
    refresh();
  };

  if (!authenticated) {
    return (
      <div className="auth-container">
        <img src={process.env.PUBLIC_URL + '/images/brain.png'} alt="Description of the image" style={{width:"auto" , height:"200px"}} />
        <h2 style={{fontSize:"xx-large", marginBottom:"5px", marginTop:"10px", fontWeight:"bold"}}>İnme Merkezi Hasta Takip Sistemi</h2>
        <h1 className='signInHeader'>Lütfen Giriş Yapınız</h1>
        {/* Display error message in red color */}
        
        <input
          type="text"
          placeholder="E-Posta Adresiniz"
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Şifreniz"
          onChange={(e) => setPassword(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              authenticate(username, password);
            }
          }}
        />
        <button className="auth-button" onClick={() => authenticate(username, password)}>Giriş Yap</button>
        {/*<button className="forgot-button" onClick={forgotPassword}>Forgot Password</button}*/}
        <p style={{ color: authStatus === "Giriş yapılıyor..." ? 'green':'red' }}>{authStatus}</p>
      </div>
    );
  }

  const refresh = () => {
    document.location.reload()
  };

 
  
  const getUserName = (user_id) => {
    const user = userData.find((item) => item.user_id?.S === user_id);
    return user ? user.name?.S : '';
  };
  
  const getUserSurname = (user_id) => {
    const user = userData.find((item) => item.user_id?.S === user_id);
    return user ? user.surname?.S : '';
  };
  
  const getUserJob = (user_id) => {
    const user = userData.find((item) => item.user_id?.S === user_id);
    return user ? user.job?.S : '';
  };

  const calculateGradientColor = (percentage) => {

    const red = 255 - (percentage * 2.55); // Scale percentage to 0-255 for red
    const green = percentage * 2.55 -50; // Scale percentage to 0-255 for green
    if(percentage === "-"){
      return `rgb(${red}, ${green}, 0)`;
    }
    return `rgb(${red}, ${green}, 0)`;
  };
  const getUserPhoneNumber = (user_id) => {
    const user = userData.find((item) => item.user_id?.S === user_id);
    return user ? user.phoneNumber?.S : '';
  };

  const getUserDate = (user_id) => {
    const user = userData.find((item) => item.user_id?.S === user_id);
    return user ? user.date?.S : '';
  };

  const getUserTime = (user_id) => {
    const user = userData.find((item) => item.user_id?.S === user_id);
    return user ? user.time?.S : '';
  };

  const getUserHospital = (user_id) => {
    const user = userData.find((item) => item.user_id.S === user_id);
    return user ? user.hospital.S : '';
  };

  const getAssignmentNumberGreen = (user_id) => {
    let string = "";  
    station_list.forEach((station) => {
      const total = Number(userListwStations[user_id][station].green) + Number(userListwStations[user_id][station].red);
      console.log(total);
      string += total != 0 ? ( ( station.slice(2,)  + ": " + "<b>" +userListwStations[user_id][station].green + "/"+ (Number(userListwStations[user_id][station].green) + Number(userListwStations[user_id][station].red) )+ "</b>"+ "<br><br>")):"";
    });
    return string;
  };

  const getAssignmentNumberEachStation = (user_id,station) => {
    let string = "";  
      const total = Number(userListwStations[user_id][station].green) + Number(userListwStations[user_id][station].red);
      string += total != 0 ? ( userListwStations[user_id][station].green / total).toFixed(2):"-";
      return string;
  
  };

  if (authenticated) {
    return (
      <div>
        <Navbar handleLogout={logout} name={dashboardUserName}/>
        <div className='container'>
        <div className='user-container'> 
          <div className='title-div'>
             <h2 className='title'>Kullanıcılar</h2>
          </div>
          <div className='space'></div>
          <div className='excel-div'>
            <FontAwesomeIcon onClick={refresh} icon={faArrowsRotate}/>  
            <button className="csv_buton" onClick={downloadExcel}>
                    <FontAwesomeIcon className="icon" icon={faTable} /> Excel olarak indir
            </button>
          
          </div>
        </div>
        <div className='table-container'>

        <table>
          <thead>
            <tr>
              <th>Kullanıcı ID</th>
              <th>Adı</th>
              <th>Soyadı</th>
              <th>Kullanıcı Tipi</th>
              <th>Telefon Numarası</th>
              <th>Kayıt Tarihi</th>
              <th>Kayıt Saati</th>
              <th>Başarı oranı</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(userList).map(([userId, { red, green }]) => {
              let successPercentage;

              if((!((green / (red + green)) * 100)>0)){
                successPercentage = "-";
              }
              else{
                successPercentage = (green / (red + green)) * 100;
              }
       
              const gradientColor = calculateGradientColor(successPercentage);

              return (
                <tr key={userId}>
                  <td>{userId}</td>
                  <td>{getUserName(userId)}</td>
                  <td>{getUserSurname(userId)}</td>
                  <td>{getUserJob(userId)}</td>
                  <td>{getUserPhoneNumber(userId)}</td>
                  <td>{getUserDate(userId)}</td>
                  <td>{getUserTime(userId)}</td>
                  <td className="dialog-trigger" style={{ background: `linear-gradient(to right, ${gradientColor}, ${gradientColor})` }}>    
                  <div className="dialog-box" dangerouslySetInnerHTML={{ __html: getAssignmentNumberGreen(userId)}} />
                    <span style={{ color: 'white',fontSize:'1em',textAlign:"center", alignItems:"center"}}>
                      {successPercentage !== "-" ? successPercentage.toFixed(2): ""}%
                    </span>
                  </td>
                  <td>
                      <FontAwesomeIcon className="icon" onClick={() => handleDeleteUser(userId)} icon={faTrash} />
                    </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <table style={{display:"none"}}>
          <thead>
            <tr>
              <th>Çalışan ID</th>
              <th>Adı</th>
              <th>Soyadı</th>
              <th>Kullanıcı Tipi</th>
              <th>Telefon Numarası</th>
              <th>Kayıt Tarihi</th>
              <th>Kayıt Saati</th>
              {
                station_list.map((station) => (
                  <th key={station.id}>
                    {station}
                  </th>
                ))
              }
            </tr>
          </thead>
          <tbody>
            {Object.entries(userList).map(([userId, { red, green }]) => {
              const successPercentage = (green / (red + green)) * 100;
              const gradientColor = calculateGradientColor(successPercentage !== null ? successPercentage : 0);

              return (
                <tr key={userId}>
                  <td>{userId}</td>
                  <td>{getUserName(userId)}</td>
                  <td>{getUserSurname(userId)}</td>
                  <td>{getUserJob(userId)}</td>
                  <td>{getUserPhoneNumber(userId)}</td>
                  <td>{getUserDate(userId)}</td>
                  <td>{getUserTime(userId)}</td>
                  {
                    station_list.map((station) => (
                      <td key={station.id}>
                        {getAssignmentNumberEachStation(userId, station)}
                      </td>
                    ))
                  }
                 
                  <td>
                      <FontAwesomeIcon className="icon" onClick={() => handleDeleteUser(userId)} icon={faTrash} />
                    </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        </div>
        </div>
       
      </div>
    );
      }
  }  

export default Performances;
