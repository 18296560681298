import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from '../components/Navbar';
import '../styles/Tables.css';
import { Auth } from 'aws-amplify';

function PatientTable() {
  const [patientData, setPatientData] = useState([]);

  useEffect(() => {
    fetchPatientData();
  }, []);

  const fetchPatientData = async () => {
    try {
      const response = await axios.get('/api/patientData');
      setPatientData(response.data);
    } catch (error) {
      console.error('Failed to fetch patient data:', error);
    }
  };

  // Function to convert time string to seconds
  function timeToSeconds(timeString) {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }

  const setUsers = () => {
    const newData = {};
    for (let i = 0; i < patientData.length; i++) {
      const user_id = patientData[i]?.user_id?.S;
      if (user_id) {
        newData[user_id] = {
        
          "2-Acil Hekimi Muayene": { red: 0, green: 0 },
          "3-Kan Alma":  { red: 0, green: 0 },
          "4-Acil - Görüntüleme Transfer":  { red: 0, green: 0 },
          "5-BT Kabul":  { red: 0, green: 0 },
          "6-Nörolog Muayene":  { red: 0, green: 0 },
          "7-Trombolitik Tedavisi":  { red: 0, green: 0 },
          "8-Anjiyografi Kabul":  { red: 0, green: 0 },
          "9-İnme Merkezine Yatış":  { red: 0, green: 0 },
        };
      }
    }
    return newData;
  };

  const updateUserStationData = (data, user_id, stationName, color) => {
    if (data?.[user_id]?.[stationName] && color === "red") {
      data[user_id][stationName].red += 1;
    }
    else if (data?.[user_id]?.[stationName] && color === "green") {
      data[user_id][stationName].green += 1;
    }
  };

function setUserList(){
    let userList = setUsers();
    return userList;
  }

  let userList = setUserList();

  function calculateTimePassForPatient(patientData) {
    // Threshold values for stations
    const acilHekimiTH = 22;
    const kanAlmaTH = 10;
    const acilGörüntülemeTH = 11;
    const btKabulTH = 6;
    const nörologMuayeneTH = 25;
    const trombolikTH = 25;
    const anjiyografiTH = 20;
    const inmeMerkeziTH = 11;
    
    const timePasses = [];
    const colors = [];
    const users = [];
    
    for (let i = 1; i < patientData.length ; i++) {

      const prevStation = patientData[i-1]?.station?.S;
      const nextStation = patientData[i]?.station?.S;

      let indexNext = Number(nextStation?.charAt(0));
      let indexPrev = Number(prevStation?.charAt(0));

      if(indexNext !== indexPrev+1){
        for(let k=0; k<indexNext-indexPrev-1; k++){
          timePasses.push(null);
          colors.push("grey");
          users.push("yok")
        }     
        const currentTime = timeToSeconds(patientData[i-1]?.time?.S);
        const nextTime = timeToSeconds(patientData[i]?.time?.S);
        const timePass = nextTime - currentTime;
        const timePassBeginning = nextTime - timeToSeconds(patientData[0]?.time?.S);

        if(nextStation === "3-Kan Alma"){
          if(timePass < kanAlmaTH){
            colors.push("green");
 
          }
          else if(timePass>kanAlmaTH && timePassBeginning < acilHekimiTH + kanAlmaTH){
            colors.push("green");
       
          }
          else if(timePassBeginning > acilHekimiTH + kanAlmaTH){
            colors.push("red");
          
          }
          else{
            colors.push("green");
  
          }
        }

        if(nextStation === "4-Acil - Görüntüleme Transfer"){
          if(timePass < acilGörüntülemeTH){
            colors.push("green");
           
          }
          else if(timePass>acilGörüntülemeTH && timePassBeginning < acilHekimiTH + kanAlmaTH + acilGörüntülemeTH){
            colors.push("green");
            
          }
          else if(timePassBeginning > acilHekimiTH + kanAlmaTH + acilGörüntülemeTH){
            colors.push("red");
            
          }
          else{
            colors.push("green");
            
          }
        }

        if(nextStation === "5-BT Kabul"){
          if(timePass < btKabulTH){
            colors.push("green");
          }
          else if(timePass>btKabulTH && timePassBeginning < acilHekimiTH + kanAlmaTH + acilGörüntülemeTH + btKabulTH){
            colors.push("green");
          }
          else if(timePassBeginning > acilHekimiTH + kanAlmaTH + acilGörüntülemeTH + btKabulTH){
            colors.push("red");
          }
          else{
            colors.push("green");
          }
        }

        if(nextStation === "6-Nörolog Muayene"){
          if(timePass < nörologMuayeneTH){
            colors.push("green");
          }
          else if(timePass>nörologMuayeneTH && timePassBeginning < acilHekimiTH + kanAlmaTH + acilGörüntülemeTH + btKabulTH + nörologMuayeneTH){
            colors.push("green");
          }
          else if(timePassBeginning > acilHekimiTH + kanAlmaTH + acilGörüntülemeTH + btKabulTH + nörologMuayeneTH){
            colors.push("red");
          }
          else{
            colors.push("green");
          }
        }

        if(nextStation === "7-Trombolitik Tedavisi"){
          if(timePass < trombolikTH){
            colors.push("green");
          }
          else if(timePass>trombolikTH && timePassBeginning < acilHekimiTH + kanAlmaTH + acilGörüntülemeTH + btKabulTH + nörologMuayeneTH + trombolikTH){
            colors.push("green");
          }
          else if(timePassBeginning > acilHekimiTH + kanAlmaTH + acilGörüntülemeTH + btKabulTH + nörologMuayeneTH + trombolikTH){
            colors.push("red");
          }
          else{
            colors.push("green");
          }
        }

        if(nextStation === "8-Anjiyografi Kabul"){
          if(timePass < anjiyografiTH){
            colors.push("green");
          }
          else if(timePass>anjiyografiTH && timePassBeginning < acilHekimiTH + kanAlmaTH + acilGörüntülemeTH + btKabulTH + nörologMuayeneTH + trombolikTH + anjiyografiTH){
            colors.push("green");
          }
          else if(timePassBeginning > acilHekimiTH + kanAlmaTH + acilGörüntülemeTH + btKabulTH + nörologMuayeneTH + trombolikTH + anjiyografiTH){
            colors.push("red");
          }
          else{
            colors.push("green");
          }
        }

        if(nextStation === "9-İnme Merkezine Yatış"){
          if(timePass < inmeMerkeziTH){
            colors.push("green");
          }
          else if(timePass>anjiyografiTH && timePassBeginning < acilHekimiTH + kanAlmaTH + acilGörüntülemeTH + btKabulTH + nörologMuayeneTH + trombolikTH + anjiyografiTH + inmeMerkeziTH){
            colors.push("green");
          }
          else if(timePassBeginning > acilHekimiTH + kanAlmaTH + acilGörüntülemeTH + btKabulTH + nörologMuayeneTH + trombolikTH + anjiyografiTH + inmeMerkeziTH){
            colors.push("red");
          }
          else{
            colors.push("green");
          }
        }
        timePasses.push(timePass);
        users.push(patientData[i]?.user_id?.S)
        updateUserStationData(userList, patientData[i-1]?.user_id?.S, nextStation, colors[colors.length-1]);
      }
      // Check if both currentStation and nextStation are defined and their first characters are the same
      else{
        const currentTime = timeToSeconds(patientData[i-1]?.time?.S);
        const nextTime = timeToSeconds(patientData[i]?.time?.S);
        const timePass = nextTime - currentTime;
        const timePassBeginning = nextTime - timeToSeconds(patientData[0]?.time?.S);
        if(nextStation === "2-Acil Hekimi Muayene"){
          if(timePass>acilHekimiTH){
            colors.push("red");
          }
          else{
            colors.push("green");
          }
        }
        if(nextStation === "3-Kan Alma"){
          if(timePass < kanAlmaTH){
            colors.push("green");
          }
          else if(timePass>kanAlmaTH && timePassBeginning < acilHekimiTH + kanAlmaTH){
            colors.push("green");
          }
          else if(timePassBeginning > acilHekimiTH + kanAlmaTH){
            colors.push("red");
          }
          else{
            colors.push("green");
          }
        }

        if(nextStation === "4-Acil - Görüntüleme Transfer"){
          if(timePass < acilGörüntülemeTH){
            colors.push("green");
          }
          else if(timePass>acilGörüntülemeTH && timePassBeginning < acilHekimiTH + kanAlmaTH + acilGörüntülemeTH){
            colors.push("green");
          }
          else if(timePassBeginning > acilHekimiTH + kanAlmaTH + acilGörüntülemeTH){
            colors.push("red");
          }
          else{
            colors.push("green");
          }
        }

        if(nextStation === "5-BT Kabul"){
          if(timePass < btKabulTH){
            colors.push("green");
          }
          else if(timePass>btKabulTH && timePassBeginning < acilHekimiTH + kanAlmaTH + acilGörüntülemeTH + btKabulTH){
            colors.push("green");
          }
          else if(timePassBeginning > acilHekimiTH + kanAlmaTH + acilGörüntülemeTH + btKabulTH){
            colors.push("red");
          }
          else{
            colors.push("green");
          }
        }

        if(nextStation === "6-Nörolog Muayene"){
          if(timePass < nörologMuayeneTH){
            colors.push("green");
          }
          else if(timePass>nörologMuayeneTH && timePassBeginning < acilHekimiTH + kanAlmaTH + acilGörüntülemeTH + btKabulTH + nörologMuayeneTH){
            colors.push("green");
          }
          else if(timePassBeginning > acilHekimiTH + kanAlmaTH + acilGörüntülemeTH + btKabulTH + nörologMuayeneTH){
            colors.push("red");
          }
          else{
            colors.push("green");
          }
        }

        if(nextStation === "7-Trombolitik Tedavisi"){
          if(timePass < trombolikTH){
            colors.push("green");
          }
          else if(timePass>trombolikTH && timePassBeginning < acilHekimiTH + kanAlmaTH + acilGörüntülemeTH + btKabulTH + nörologMuayeneTH + trombolikTH){
            colors.push("green");
          }
          else if(timePassBeginning > acilHekimiTH + kanAlmaTH + acilGörüntülemeTH + btKabulTH + nörologMuayeneTH + trombolikTH){
            colors.push("red");
          }
          else{
            colors.push("green");
          }
        }

        if(nextStation === "8-Anjiyografi Kabul"){
          if(timePass < anjiyografiTH){
            colors.push("green");
          }
          else if(timePass>anjiyografiTH && timePassBeginning < acilHekimiTH + kanAlmaTH + acilGörüntülemeTH + btKabulTH + nörologMuayeneTH + trombolikTH + anjiyografiTH){
            colors.push("green");
          }
          else if(timePassBeginning > acilHekimiTH + kanAlmaTH + acilGörüntülemeTH + btKabulTH + nörologMuayeneTH + trombolikTH + anjiyografiTH){
            colors.push("red");
          }
          else{
            colors.push("green");
          }
        }

        if(nextStation === "9-İnme Merkezine Yatış"){
          if(timePass < inmeMerkeziTH){
            colors.push("green");
          }
          else if(timePass>anjiyografiTH && timePassBeginning < acilHekimiTH + kanAlmaTH + acilGörüntülemeTH + btKabulTH + nörologMuayeneTH + trombolikTH + anjiyografiTH + inmeMerkeziTH){
            colors.push("green");
          }
          else if(timePassBeginning > acilHekimiTH + kanAlmaTH + acilGörüntülemeTH + btKabulTH + nörologMuayeneTH + trombolikTH + anjiyografiTH + inmeMerkeziTH){
            colors.push("red");
          }
          else{
            colors.push("green");
          }
        }

        timePasses.push(timePass);
        users.push(patientData[i-1]?.user_id?.S);
        updateUserStationData(userList, patientData[i-1]?.user_id?.S, nextStation, colors[colors.length-1]);
      }

    }
    
  }
  function calculateTimePassForAllPatients(data) {
    const patients = {};
    data.forEach((entry) => {
      const patientId = entry.patient_id.S;
      if (!patients[patientId]) {
        patients[patientId] = [];
      }
      patients[patientId].push(entry);
    });

    const patientTimePasses = {};
    const colorsAll = {};
    const allUsers = {};
    for (const patientId in patients) {
      calculateTimePassForPatient(patients[patientId]);

    }

  }

  // Function to calculate time pass for all patients
  calculateTimePassForAllPatients(patientData);

  let authenticated = localStorage.getItem('authenticated')?true:false;

  const logout = async () => {
    try {
      await Auth.signOut();
      authenticated = false;
      localStorage.removeItem('authenticated');
      // Refresh the page
      window.location.reload(true);

    } catch (error) {
      console.error('Logout failed:', error);
      window.location.reload();
      window.redirect('/login');
    }
  };

  if (!authenticated) {
    console.log('Authentication', authenticated);
    return (
      <div className="auth-container">
          <h1><a href='/'>Lütfen Giriş Yapınız</a></h1>
      </div>
    );
  }

  return (
    <div>
    <Navbar  handleLogout = {logout} />
    <h2>Çalışanların İstasyonlardaki Başarı Oranları</h2>
    <div className='container'>
      <table className="patient-table">
        <thead>
          <tr>
            <th>User ID</th>
            <th className='double-header'>2-Acil Hekimi Muayene (Başarısız)</th>
            <th className='double-header'>2-Acil Hekimi Muayene (Başarılı)</th>
            <th className='odd-header'>3-Kan Alma (Başarısız)</th>
            <th className='odd-header'>3-Kan Alma (Başarılı)</th>
            <th className='double-header'>4-Acil - Görüntüleme Transfer (Başarısız)</th>
            <th className='double-header'>4-Acil - Görüntüleme Transfer (Başarılı)</th>
            <th className='odd-header'>5-BT Kabul (Başarısız)</th>
            <th className='odd-header'>5-BT Kabul (Başarılı)</th>
            <th className='double-header'>6-Nörolog Muayene (Başarısız)</th>
            <th className='double-header'>6-Nörolog Muayene (Başarılı)</th>
            <th className='odd-header'>7-Trombolitik Tedavisi (Başarısız)</th>
            <th className='odd-header'>7-Trombolitik Tedavisi (Başarılı)</th>
            <th className='double-header'>8-Anjiyografi Kabul (Başarısız)</th>
            <th className='double-header'>8-Anjiyografi Kabul (Başarılı)</th>
            <th className='odd-header'>9-İnme Merkezine Yatış (Başarısız)</th>
            <th className='odd-header'>9-İnme Merkezine Yatış (Başarılı)</th>
            {/* Add other stations as needed */}
          </tr>
        </thead>
        <tbody>
          {Object.keys(userList).map((userId) => (
            <tr key={userId}>
              <td>{userId}</td>
              <td className="double-red">{userList[userId]["2-Acil Hekimi Muayene"].red !== 0 ? userList[userId]["2-Acil Hekimi Muayene"].red+" (%"+(userList[userId]["2-Acil Hekimi Muayene"].red/(userList[userId]["2-Acil Hekimi Muayene"].red+userList[userId]["2-Acil Hekimi Muayene"].green)).toFixed(2)*100 +")":"0"}</td>
              <td className="double-green">{userList[userId]["2-Acil Hekimi Muayene"].green !== 0 ? userList[userId]["2-Acil Hekimi Muayene"].green+" (%"+(userList[userId]["2-Acil Hekimi Muayene"].green/(userList[userId]["2-Acil Hekimi Muayene"].red+userList[userId]["2-Acil Hekimi Muayene"].green)).toFixed(2)*100 +")":"0"}</td>
              <td className='odd-red'>{userList[userId]["3-Kan Alma"].red !== 0 ? userList[userId]["3-Kan Alma"].red+" (%"+(userList[userId]["3-Kan Alma"].red/(userList[userId]["3-Kan Alma"].red+userList[userId]["3-Kan Alma"].green)).toFixed(2)*100 +")":"0"}</td>
              <td className='odd-green'>{userList[userId]["3-Kan Alma"].green !== 0 ? userList[userId]["3-Kan Alma"].green+" (%"+(userList[userId]["3-Kan Alma"].green/(userList[userId]["3-Kan Alma"].red+userList[userId]["3-Kan Alma"].green)).toFixed(2)*100 +")":"0"}</td>
              <td className="double-red">{userList[userId]["4-Acil - Görüntüleme Transfer"].red !== 0 ? userList[userId]["4-Acil - Görüntüleme Transfer"].red+" (%"+(userList[userId]["4-Acil - Görüntüleme Transfer"].red/(userList[userId]["4-Acil - Görüntüleme Transfer"].red+userList[userId]["4-Acil - Görüntüleme Transfer"].green)).toFixed(2)*100 +")":"0"}</td>
              <td className="double-green">{userList[userId]["4-Acil - Görüntüleme Transfer"].green !== 0 ? userList[userId]["4-Acil - Görüntüleme Transfer"].green+" (%"+(userList[userId]["4-Acil - Görüntüleme Transfer"].green/(userList[userId]["4-Acil - Görüntüleme Transfer"].red+userList[userId]["4-Acil - Görüntüleme Transfer"].green)).toFixed(2)*100 +")":"0"}</td>
              <td className='odd-red'>{userList[userId]["5-BT Kabul"].red !== 0 ? userList[userId]["5-BT Kabul"].red+" (%"+(userList[userId]["5-BT Kabul"].red/(userList[userId]["5-BT Kabul"].red+userList[userId]["5-BT Kabul"].green)).toFixed(2)*100 +")":"0"}</td>
              <td className='odd-green'>{userList[userId]["5-BT Kabul"].green !== 0 ? userList[userId]["5-BT Kabul"].green+" (%"+(userList[userId]["5-BT Kabul"].green/(userList[userId]["5-BT Kabul"].red+userList[userId]["5-BT Kabul"].green)).toFixed(2)*100 +")":"0"}</td>
              <td className="double-red">{userList[userId]["6-Nörolog Muayene"].red !== 0 ? userList[userId]["6-Nörolog Muayene"].red+" (%"+(userList[userId]["6-Nörolog Muayene"].red/(userList[userId]["6-Nörolog Muayene"].red+userList[userId]["6-Nörolog Muayene"].green)).toFixed(2)*100 +")":"0"}</td>
              <td className="double-green">{userList[userId]["6-Nörolog Muayene"].green !== 0 ? userList[userId]["6-Nörolog Muayene"].green+" (%"+(userList[userId]["6-Nörolog Muayene"].green/(userList[userId]["6-Nörolog Muayene"].red+userList[userId]["6-Nörolog Muayene"].green)).toFixed(2)*100 +")":"0"}</td>
              <td className='odd-red'>{userList[userId]["7-Trombolitik Tedavisi"].red !== 0 ? userList[userId]["7-Trombolitik Tedavisi"].red+" (%"+(userList[userId]["7-Trombolitik Tedavisi"].red/(userList[userId]["7-Trombolitik Tedavisi"].red+userList[userId]["7-Trombolitik Tedavisi"].green)).toFixed(2)*100 +")":"0"}</td>
              <td className='odd-green'>{userList[userId]["7-Trombolitik Tedavisi"].green !== 0 ? userList[userId]["7-Trombolitik Tedavisi"].green+" (%"+(userList[userId]["7-Trombolitik Tedavisi"].green/(userList[userId]["7-Trombolitik Tedavisi"].red+userList[userId]["7-Trombolitik Tedavisi"].green)).toFixed(2)*100 +")":"0"}</td> 
              <td className="double-red">{userList[userId]["8-Anjiyografi Kabul"].red !== 0 ? userList[userId]["8-Anjiyografi Kabul"].red+" (%"+(userList[userId]["8-Anjiyografi Kabul"].red/(userList[userId]["8-Anjiyografi Kabul"].red+userList[userId]["8-Anjiyografi Kabul"].green)).toFixed(2)*100 +")":"0"}</td>
              <td className="double-green">{userList[userId]["8-Anjiyografi Kabul"].green !== 0 ? userList[userId]["8-Anjiyografi Kabul"].green+" (%"+(userList[userId]["8-Anjiyografi Kabul"].green/(userList[userId]["8-Anjiyografi Kabul"].red+userList[userId]["8-Anjiyografi Kabul"].green)).toFixed(2)*100 +")":"0"}</td>   
              <td className='odd-red'>{userList[userId]["9-İnme Merkezine Yatış"].red !== 0 ? userList[userId]["9-İnme Merkezine Yatış"].red+" (%"+(userList[userId]["9-İnme Merkezine Yatış"].red/(userList[userId]["9-İnme Merkezine Yatış"].red+userList[userId]["9-İnme Merkezine Yatış"].green)).toFixed(2)*100 +")":"0"}</td>
              <td className='odd-green'>{userList[userId]["9-İnme Merkezine Yatış"].green !== 0 ? userList[userId]["9-İnme Merkezine Yatış"].green+" (%"+((userList[userId]["9-İnme Merkezine Yatış"].green/(userList[userId]["9-İnme Merkezine Yatış"].red+userList[userId]["9-İnme Merkezine Yatış"].green)).toFixed(2)*100).toFixed(2) +")":"0"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
  );
}
export default PatientTable;
