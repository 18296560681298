import "../styles/styles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';


export default function Navbar({handleLogout, name}){
    const [username, setUserName] = useState();
    useEffect(() => {
        setUserName(name);
      }, []);
      
    return(
        <nav className="nav">
            <div className="header-container">
                <img src={process.env.PUBLIC_URL + '/images/brain.png'} alt="Description of the image" style={{width:"auto" , height:"60px"}} />
                <a href="/" className="site-title">İnme Merkezi HTS <span style={{fontSize:"xx-large",marginBottom:"0.4vh"}}>|</span><span>Yönetici Paneli</span></a>
                <img src={process.env.PUBLIC_URL + '/images/speedometer.png'} alt="Description of the image" style={{width:"auto" , height:"30px", marginLeft: "0.5vh", marginRight:"0.5vh"}} />
                <span style={{fontSize:"xx-large",marginBottom:"0.4vh"}}>|</span>
                {name === "superadmin" ? <span>Bütün Hastaneler</span> : name === "admin1" ? <span>Kartal Dr. Lütfi Kırdar Ş.H.</span>:name === "admin2" ?<span>İSÜ Liv Hospital Bahçeşehir</span>: name === "admin3" && <span>Başakşehir Çam ve Sakura Ş.H.</span>  }
            </div>
            <ul>
                <li>
                    <a href="/">Hastalar</a>
                </li>
                <li>
                    <a href="/performances">Kullanıcılar</a>
                </li>
                <li>
                    <a href="/records">Kayıtlar</a>
                </li>
                {/*<li>
                    <a href="/usertable">Çalışan Tablosu</a>
                </li>
                <li>
                    <a href="/thresholdtable">Sıkıntılı Çalışanlar</a>
                </li>*/}
                <div className="user-info">
                    <div className="log-icon">
                    <li>
                    <FontAwesomeIcon className="logoutIcon" onClick={handleLogout} icon={faArrowRightFromBracket} size="xl"/>
                    </li>
                    </div>
                    <div>
                        <p className="userName">{username}</p>
                    </div>
                </div>

            </ul>
        
        </nav>
    )
}