import React, { useState } from 'react';
import '../styles/SmallWindow.css'; // Import your CSS file for styling
import axios from 'axios';


const Popup = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [inputText, setInputText] = useState('');

  const toggleVisibility = () => {
    console.log('Toggle visibility',props.patientId);
    showNotes(props.patientId);
    setIsVisible(true);
  };

  const toggleVisibilityClose = () => {
    setIsVisible(false);
  };

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handleDeleteClick = () => {
    try {
      // Create a request payload with the input text
      const payload = { text: "" };

      // Make a POST request to your backend API
      const response = fetch('https://server.edna.com.tr/api/addNote/'+props.patientId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

    } catch (error) {
      console.error('An error occurred:', error);
      alert('Silme işlemi gerçekleştirilemedi.');
    }
    setIsVisible(false);
    window.location.reload();
  };

  const handleButtonClick =   () => {
    try {
      // Create a request payload with the input text
      const payload = { text: inputText };

      // Make a POST request to your backend API
      const response =  fetch('https://server.edna.com.tr/api/addNote/'+props.patientId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      setIsVisible(false);
      window.location.reload();
    } catch (error) {
      console.error('An error occurred:', error);
      alert('Not ekleme işlemi gerçekleştirilemedi.');
    }
  };
  
  async function showNotes(patientId) {
    try {
      const response = await axios.get('https://server.edna.com.tr/api/notes/' + patientId);
      console.log(response.data.notes);
      setInputText(response.data.notes);
    } catch (err) {
      console.log(err);
    }

  }

  return (
    <div className="small-window-container">
      <img   onClick={toggleVisibility} src={process.env.PUBLIC_URL + '/images/note_icon.png'} alt="Description of the image" style={{width:"auto" , height:"20px", cursor:"pointer"}} />
      {isVisible && (
        <div className="popup">
          <div className="popup-content">
            <span className="close-button" onClick={toggleVisibilityClose}>
              &times;
            </span>
            <div className='header'>
            <img src={process.env.PUBLIC_URL + '/images/note_icon.png'} className="note-icon" alt="Description of the image"  />
            <h3>Notlar &nbsp;-  </h3>
            <p>  </p><p><p> </p> &nbsp; {props.patientId} </p >
            </div>
            <textarea
              type="text"
              value={inputText}
              onChange={handleInputChange}
              placeholder="Notunuzu giriniz..."
              name="new_note"
              rows="10"
              cols="80%"
              style={{
                maxWidth: "100%", /* Ensure it doesn't exceed the container width */
                width: "100%", /* Ensure it takes the full container width */
                boxSizing: "border-box", /* Include padding and border in width calculation */
              }}
            />
            <div className='button-container'>
            <button className="delete-button" onClick={handleDeleteClick}>Sil</button>
            <div className='space'></div>
            <button className="adding-button" onClick={handleButtonClick}>Ekle</button>
            </div>

          </div>
        </div>
      )}
    </div>
  );
};

export default Popup;
