
// aws-exports.js
const awsmobile = {
    aws_project_region: 'eu-north-1',
    aws_cognito_region: 'eu-north-1',
    aws_user_pools_id: 'eu-north-1_r2OMxQXMV',
    aws_user_pools_web_client_id: '7megfvg28l86n1adnu2u33gsq1',
  };
  
  export default awsmobile;
  