import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from '../components/Navbar';
import Popup from '../components/Popup';
import Settings from '../components/Settings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTable } from '@fortawesome/free-solid-svg-icons';
import { Auth } from 'aws-amplify';
import '../styles/Tables.css';
import * as XLSX from 'xlsx'; 
import Barcode from 'react-barcode';
import '../styles/Loading.css';
import { faCaretUp,faCaretDown } from '@fortawesome/free-solid-svg-icons';


function PatientTable() {
  const columnsList = ["Kilit","Not","Hasta ID","Tarih","Acil Hekimi Muayene","Kan Alma","Acil-Görüntüleme Transfer","BT Kabul","Görüntüleme-Acil Transfer","Nörolog Muayene",
  "Trombolik Tedavisi","Anjiyografi Kabul","İnme Merkezine Yatış"];
  const columnsList2 = ["Kilit","Not","Hasta ID","Tarih","Acil Hekimi Muayene","AHM - ID","Kan Alma","KA - ID","Acil-Görüntüleme Transfer","AGT - ID","BT Kabul","BK - ID","Görüntüleme-Acil Transfer","GAT - ID","Nörolog Muayene","NM - ID",
  "Trombolik Tedavisi","TT - ID","Anjiyografi Kabul","AK - ID","İnme Merkezine Yatış","İMY - ID"];

  let authenticated = localStorage.getItem('authenticated')?true:false; // New state for authentication
  var isPatientAssignedFirstStationList = {};
  var lockedPatientsList = {};
  const [iconState, setIconState] = useState(false);
  const [patientData, setPatientData] = useState([]);
  const [lateAssignedPatients, setlateAssignedPatients] = useState([]);
  const [lockedPatients, setLockedPatients] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [authStatus, setAuthStatus] = useState(''); 
  const [showIDs, setShowIDs] = useState(true);
  const [patientIDs, setPatientIDs] = useState(false);
  const [dashboardUserName, setDashboardUserName] = useState(localStorage.getItem('username'));
  const [userData, setUserData] = useState([]);
  const [username, setUsername] = useState([]);
  const [password, setPassword] = useState([]);
  const [patientNotes, setPatientNotes] = useState([]);
  const [hospitalName, setHospitalName] = useState(dashboardUserName === "admin1" ? "Kartal Dr. Lütfi Kırdar Ş.H." : 
  dashboardUserName === "admin2" ? "İSÜ Liv Hospital Bahçeşehir" :
  dashboardUserName === "admin3" && "Başakşehir Çam ve Sakura Ş.H.");



  //Threshold values for stations
  const [acilHekimiTH, setAcilHekimiTH] = useState(10);
  const [kanAlmaTH, setKanAlmaTH] = useState(40);
  const [acilGörüntülemeTH, setAcilGörüntülemeTH] = useState(20);
  const [görüntülemeAcilTH, setGörüntülemeAcilTH] = useState(30);
  const [btKabulTH, setBtKabulTH] = useState(25);
  const [trombolikTH, setTrombolikTH] = useState(60);
  const [nörologMuayeneTH, setnörologMuayeneTH] = useState(45);

  useEffect(() => {
    fetchData(); // Fetch data from your APIs
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await axios.get('https://server.edna.com.tr/api/userData');
      let fetched_data = response.data;
      dashboardUserName != "superadmin" ? setUserData(filterFetcedData(response.data)) : setUserData(fetched_data);

    } catch (error) {
      console.error('Failed to fetch user data:', error);
    }
  };

  const filterFetcedData = (data) => {
    let filtered_data = [];

    data.forEach(element => {
      if(element.hospital.S === hospitalName){
        filtered_data.push(element);
      }
    });
    return filtered_data;
  }

  async function fetchData() {
    try {
      const patientDataResponse = await axios.get('https://server.edna.com.tr/api/patientData');
      const lockedPatientsResponse = await axios.get('https://server.edna.com.tr/api/patientInfo');
      const users = await axios.get('https://server.edna.com.tr/api/userData');
      const notes = (await axios.get('https://server.edna.com.tr/api/notes')).data.notes;

      let patientIdsPatientList =[];
      for (const patient of patientDataResponse.data) {
        patientIdsPatientList.push(patient.patient_id.S);
      }

      console.log(patientIdsPatientList);

      
      for (const patient of lockedPatientsResponse.data) {
        isPatientAssignedFirstStationList[patient.patient_id.S] = patient.isLateAssignment.BOOL;
        if(patientIdsPatientList.includes(patient.patient_id.S))
          lockedPatientsList[patient.patient_id.S] = patient.isClosed.BOOL;
        }
        
      let item = [];
      notes.forEach((note)=>{
        const note_item = note.notes.S;
        const patient_id = note.patient_id.S;
        item[patient_id] = note_item;

      })
      setPatientNotes(item)
      const patientData = patientDataResponse.data;
      dashboardUserName != "superadmin" ? setPatientData(filterFetcedData(patientDataResponse.data)) : setPatientData(patientData);
      setLockedPatients(lockedPatientsList);
      setlateAssignedPatients(isPatientAssignedFirstStationList);
      setUserData(users.data);
      setDataFetched(true); // Mark data as fetched
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  if (!dataFetched) {
    return (
      <body className='loading-body'>
        <div className="loading-container">
          <div className="loading-spinner"></div>
          <p>Yükleniyor...</p>
        </div>
      </body>

  ); // Show loading message or spinner until data is fetched
  }


  // Function to convert time string to seconds
  function timeToSeconds(timeString) {
   
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }
  

  const setUsers = () => {
    const newData = {};
    for (let i = 0; i < patientData.length; i++) {
      const user_id = patientData[i]?.user_id?.S;
      if (user_id) {
        newData[user_id] = {
        
          "2-Acil Hekimi Muayene": { red: 0, green: 0 },
          "3-Kan Alma":  { red: 0, green: 0 },
          "4-Acil - Görüntüleme Transfer":  { red: 0, green: 0 },
          "5-BT Kabul":  { red: 0, green: 0 },
          "6-Görüntüleme Acil Transfer": { red: 0, green: 0 },
          "7-Nörolog Muayene":  { red: 0, green: 0 },
          "8-Trombolitik Tedavisi":  { red: 0, green: 0 },
          "9-Anjiyografi Kabul":  { red: 0, green: 0 },
          "10-İnme Merkezine Yatış":  { red: 0, green: 0 },
        };
      }
    }
    return newData;
  };

  const updateUserStationData = (data, user_id, stationName, color) => {
    if (data?.[user_id]?.[stationName] && color === "red") {
      data[user_id][stationName].red += 1;
    }
    else if (data?.[user_id]?.[stationName] && color === "green") {
      data[user_id][stationName].green += 1;
    }
  };

function setUserList(){
    let userList = setUsers();
    return userList;
  }

  let userList = setUserList();

  function calculateTimePassForPatient(patientData) {
    // Threshold values for stations

    const timePasses = [];
    const colors = [];
    const users = [];
    const date = patientData[0]?.date?.S;


    // Sort the data based on time values
    patientData = patientData.sort((a, b) => {
      const timeA = Number(a.station.S.slice(0,a.station.S.indexOf("-")));
      const timeB = Number(b.station.S.slice(0,b.station.S.indexOf("-")));
      
      // Use numerical comparison instead of localeCompare
      return timeA - timeB;
    });

    for (let k = 1; k < patientData.length ; k++) {
      var A;
      var K;
      var AGT;
      var BT;
      var GAT;
      var N;
      var TT;
      var AK;
      var IMY;

      var prevStation1 = patientData[k-1]?.station?.S;
      var nextStation1 = patientData[k]?.station?.S;

      let indexNext = Number(nextStation1.slice(0, nextStation1.indexOf("-")));
      let indexPrev = Number(prevStation1.slice(0, prevStation1.indexOf("-")));


      const t0 = timeToSeconds(patientData[0]?.time?.S);
      const nextTime = timeToSeconds(patientData[k]?.time?.S);
      let timePass = nextTime - t0;
      const dateString_before = patientData[0]?.date?.S
      const dateString_after = patientData[k]?.date?.S

      const parts_before = dateString_before.split("-");
      const day_before = parseInt(parts_before[0]);
      const month_before = parseInt(parts_before[1]); // Subtract 1 from the month as months are zero-based (0-11)
      const year_before = parseInt(parts_before[2]); // Assuming 'yy' represents a two-digit year (e.g., '23' -> 2023)

      const parts_after = dateString_after.split("-");
      const day_after = parseInt(parts_after[0]);
      const month_after = parseInt(parts_after[1]); // Subtract 1 from the month as months are zero-based (0-11)
      const year_after = parseInt(parts_after[2]); // Assuming 'yy' represents a two-digit year (e.g., '23' -> 2023)


      const day_diff = day_after - day_before;
      const month_diff = month_after - month_before;
      const year_diff = year_after - year_before;

      timePass = timePass + day_diff*24*60*60 + (day_before == 31 ? month_diff*31*24*60*60 : month_diff*30*24*60*60) + year_diff*365*30*24*60*60;

      
    if(indexNext !== indexPrev+1){
        for(let k=0; k<indexNext-indexPrev-1; k++){
          timePasses.push("Atlanmış");
        } 
      }
    if(nextStation1 === "2-Acil Hekimi Muayene"){
      A = timePass;
      timePasses.push(A);
      }
  
    else if(nextStation1 === "3-Kan Alma"){
      K = timePass;
      timePasses.push(K);
    }

    else if(nextStation1 === "4-Acil - Görüntüleme Transfer"){
      AGT = timePass;
      timePasses.push(AGT);
    }

    else if(nextStation1 === "5-BT Kabul"){
       BT = timePass;
       timePasses.push(BT);
    }

    else if(nextStation1 === "6-Görüntüleme - Acil Transfer"){
      GAT = timePass;
      timePasses.push(GAT);
    }

    else if(nextStation1 === "7-Nörolog Muayene"){
      N = timePass;
      timePasses.push(N);
    }

    else if(nextStation1 === "8-Trombolitik Tedavisi"){
      TT = timePass;
      timePasses.push(TT);
  }
    else if(nextStation1 === "9-Anjiyografi Kabul"){
      AK = timePass;
      timePasses.push(AK);
    }
    else if(nextStation1 === "10-İnme Merkezine Yatış"){
      IMY = timePass;
      timePasses.push(IMY);
    }
    }
    for (let i = 1; i < patientData.length; i++) {

      const prevStation = patientData[i-1]?.station?.S;
      const nextStation = patientData[i]?.station?.S;

      let indexNext = Number(nextStation.slice(0, nextStation.indexOf("-")));
      let indexPrev = Number(prevStation.slice(0, prevStation.indexOf("-")));

      if(indexNext !== indexPrev+1){
        for(let k=0; k<indexNext-indexPrev-1; k++){
          colors.push("grey");
          users.push("yok")
        } 
        
      }
      if(nextStation === "2-Acil Hekimi Muayene"){
        if(A < 0){
          colors.push("orange");
        }
        else{
          if(A > acilHekimiTH *60){
            colors.push("red");
          }
          else{
            if(K>AGT){
              if(BT > btKabulTH *60 && (BT-AGT) < 5){
                colors.push("red");
              }
              else{
                colors.push("green");
              }
            }
            else{
              if(BT > btKabulTH *60 && (BT-AGT) < 5 && K<AGT && (AGT - K < 5)){
                colors.push("red");
              }
              else{
                colors.push("green");
              }
            }
          }
        }
      }
      
      else if(nextStation === "3-Kan Alma"){
          if(K<0){
            colors.push("orange");
          }
          else{
            if(K<AGT && AGT > 0){
              if(!(A > 0)){
                colors.push("yellow");
              }
              else{
                if((K > kanAlmaTH *60) && ((K-A) > 20)){
                  colors.push("red");
                }
                else{
                  colors.push("green");
                }
             }
            }
            else{
              if(!(A > 0) || !(AGT > 0) || !(BT > 0) || !(GAT > 0)){
                colors.push("yellow");
              }
              else{
                if((K > kanAlmaTH *60) && ((K-AGT) > 15)){
                  colors.push("red");
                }
                else{
                  colors.push("green");
                }
            }
          }
        }
      }

      else if(nextStation === "4-Acil - Görüntüleme Transfer"){
  
        if(AGT < 0){
          colors.push("orange")
        }
        else{
          if(!(A > 0)){
            colors.push("yellow");
          }
          else{
            if(K>AGT || !(K>0)){
              if((BT > btKabulTH *60) && (AGT > acilGörüntülemeTH *60) && (A <= acilHekimiTH *60)){
                colors.push("red")
              }
              else{
                colors.push("green");
              }
            }
            else{
              if((BT > btKabulTH *60) && (AGT > acilGörüntülemeTH *60) && (A <= acilHekimiTH *60) && (K<AGT) && (AGT - K <= 5)){
                colors.push("red")
              }
              else{
                colors.push("green");
              }
            }
          }
        }
      }

      else if(nextStation === "5-BT Kabul"){
        if(BT < 0){
          colors.push("orange");
        }
        else{
          if(!(A > 0) || !(AGT > 0)){
            colors.push("yellow");
          }
          else{
            if((BT > btKabulTH *60) &&  (AGT <= acilGörüntülemeTH *60) && (A <= acilHekimiTH *60)  && (K>AGT)){
              colors.push("red");
            }
            else{
              colors.push("green");
            }
          }
        }
      }

      else if(nextStation === "6-Görüntüleme - Acil Transfer"){
        if(GAT < 0){
          colors.push("orange");
        }
        else{
          if(!(A > 0) || !(AGT > 0) || !(BT > 0)){
            colors.push("yellow");
          }
          else{
            if(GAT > görüntülemeAcilTH *60) {
              colors.push("red");
            }
            else{
              colors.push("green");
            }
          }
        }
      }

      else if(nextStation === "7-Nörolog Muayene"){
        if(N < 0){
          colors.push("orange");
        }
        else{
          if(!(A > 0) || !(K > 0) || !(AGT > 0) || !(BT > 0) || !(GAT > 0)){
            colors.push("yellow");
          }
          else{
            if((N > nörologMuayeneTH *60) && (BT <= btKabulTH *60) && (GAT <= görüntülemeAcilTH *60) && (K <= kanAlmaTH *60)){
              colors.push("red");
            }
            else{
              colors.push("green");
            }
          }
        }
      }

      else if(nextStation === "8-Trombolitik Tedavisi"){
        if(TT < 0){
          colors.push("orange");
        }
        else{
          if(!(A > 0) || !(K > 0) || !(AGT > 0) || !(BT > 0) || !(GAT > 0) || !(N > 0)){
            colors.push("yellow");
          }
          else{
            if((TT > trombolikTH *60) && (N <= nörologMuayeneTH *60) && (BT <= btKabulTH *60) && (K <= kanAlmaTH *60)){
             
            }
            else{
              colors.push("green");
            }
          }
        }
      }

      else if(nextStation === "9-Anjiyografi Kabul"){
        if(AK < 0){
          colors.push("orange");
        }
        else{
          colors.push("white");
        }
   
      }

      else if(nextStation === "10-İnme Merkezine Yatış"){
        if(IMY < 0){
          colors.push("orange");
        }
        else{
          colors.push("white");
        }
   
      }
      
      users.push(patientData[i]?.user_id?.S);
      updateUserStationData(userList, patientData[i-1]?.user_id?.S, nextStation, colors[colors.length-1]);
    }
    
    return [timePasses,colors,users,date];
  }
  

  function calculateTimePassForAllPatients(data) {
    const patients = {};
    data.forEach((entry) => {
      const patientId = entry.patient_id.S;
      if (!patients[patientId]) {
        patients[patientId] = [];
      }
      patients[patientId].push(entry);
    });

    const patientTimePasses = {};
    const colorsAll = {};
    const allUsers = {};
    const dates = {};
    const isAssignedFirstStation = {};
    for (const patientId in patients) {
      const [timePass, colors, users, date] = calculateTimePassForPatient(patients[patientId]);
      patientTimePasses[patientId] = timePass;
      colorsAll[patientId] = colors;
      allUsers[patientId] = users;
      dates[patientId] = date;
    }
    // Storing colorsAll in localStorage
    localStorage.setItem('colorsAll', JSON.stringify(colorsAll));
    return [patientTimePasses, colorsAll, allUsers, dates];
  }

    // Function to calculate time pass for all patients
    const [patientTimePasses,colorsAll,allUsers,dates] = calculateTimePassForAllPatients(patientData);


  function calculateTimeFirstStationPass(data) {
    const patients = {};
    data.forEach((entry) => {
      const patientId = entry.patient_id.S;
      if (!patients[patientId]) {
        patients[patientId] = [];
      }
      patients[patientId].push(entry);
    });

    const patientTimePasses = {};
    const colorsAll = {};
    const allUsers = {};
    const dates = {};
    for (const patientId in patients) {
      const [timePass, colors, users, date] = calculateTimePassForPatient(patients[patientId]);
      patientTimePasses[patientId] = timePass;
      colorsAll[patientId] = colors;
      allUsers[patientId] = users;
      dates[patientId] = date;
    }
    // Storing colorsAll in localStorage
    localStorage.setItem('colorsAll', JSON.stringify(colorsAll));
    return [patientTimePasses, colorsAll, allUsers, dates];
  }
    // Function to calculate time pass for all patients
    const [colorsAllPassesFirstStation,allUsersPassesFirstStation,datesPassesFirstStation] = calculateTimeFirstStationPass(patientData);



  // Function to convert seconds to time string (HH:mm:ss)
  function secondsToTime(seconds) {
    if (typeof seconds === 'number') {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    } else {
      return seconds;
    }
  }

  async function lockPatient(patientId) {
    try {
      const response = await fetch('https://server.edna.com.tr/api/changeStatus/' + patientId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
  
      const responseData = await response.json(); // or response.text() depending on response type
      if (response.ok) {

      } else {
        console.error('Request was not successful. Status:', response.status);
      }
    } catch (error) {
      console.error('An error occurred during fetch:', error);
    }
  }


  
  const toggleLock = async (patientId, newLockStatus) => {
    try {
      await lockPatient(patientId);
    } catch (error) {
      console.error('Error toggling lock status:', error);
      return;
    }
    setLockedPatients(prevLockedPatients => ({
      ...prevLockedPatients,
      [patientId]: newLockStatus,
    }));

    fetchData();

  };

  const showIDchange = () => {
    setShowIDs(!showIDs); 
  };

  const showPatientchange = () => {
    setPatientIDs(!patientIDs); 
  };
  
  function filterPatients(patients, patientsToFilter) {
    var locked_patient_ids = Object.keys(patientsToFilter)
    var filteredPatients = [];
    for(var i = 0; i < locked_patient_ids.length; i++) {
      if(patientsToFilter[locked_patient_ids[i]] == false){
        filteredPatients[locked_patient_ids[i]] =patients[locked_patient_ids[i]];
        }
    }
    return filteredPatients;
  }


  
  const downloadExcel = () => {
    const currentDate = new Date();

    // To get the current date and time as a string
    const currentDateTimeString = currentDate.toString();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Months are 0-based, so add 1
    const currentDay = currentDate.getDate();
    const currentHour = currentDate.getHours();
    const currentMinute = currentDate.getMinutes();
    const originalTable = document.querySelectorAll('table')[showIDs ? 0 : 1];
    const clonedTable = originalTable.cloneNode(true);
  
    // Find and remove elements with class "dialog-box" from the cloned table
    const dialogBoxElements = clonedTable.querySelectorAll('.dialog-box');
    dialogBoxElements.forEach(dialogBox => dialogBox.remove());
  
    const wb = XLSX.utils.table_to_book(clonedTable); // Convert the cloned table to a workbook
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' }); // Write the workbook to binary format
  
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' }); // Create a Blob from binary data
    const url = URL.createObjectURL(blob);
  
    const link = document.createElement('a');
    link.href = url;
    link.download = `İnmeHTS_Hasta_Tablosu_${currentDay}-${currentMonth}-${currentYear}_${currentHour}${currentMinute}.xlsx`; // Set the desired file name
    link.click();
  
    URL.revokeObjectURL(url);
  };
  
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };
  

  // Function to get the color style based on the difference between value and average
  const getCellStyle = (value, color) => {
    if (color === "grey") 
    return {
      backgroundColor: `rgba(200, 200, 200`};
    if (color === "red") {
      return { 
        backgroundColor: `#ed6c6c` };
    } 
    if(color === "yellow") {
      return { 
        backgroundColor: `#F7E987` };
    }if(color === "green") {
      return { backgroundColor: `#70c972` };
    }
    if(color === "orange") {
      return { backgroundColor: `#FF9B50` };
    }
    else{
      return { backgroundColor: `white` };
    }
  };

  const logout = async () => {
    try {
      await Auth.signOut();
      authenticated = false;
      localStorage.removeItem('authenticated');
      setUsername('');
      setPassword('');
      setAuthStatus('');
      localStorage.setItem('username',"");
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };
  
  const authenticate = async (username, password) => {
    setAuthStatus('Giriş yapılıyor...');
    try {
      await Auth.signIn(username, password);
      authenticated = true;
      localStorage.setItem('authenticated', 'true'); // Store the authentication status in local storage
      localStorage.setItem('username', username);
      setDashboardUserName(localStorage.getItem('username'));
      window.location.reload(true);
    } catch (error) {
      console.error('Authentication failed:', error);
      authenticated = false;
      localStorage.removeItem('authenticated'); // Remove the authentication status from local storage
      setAuthStatus('Kullanıcı adı veya şifre hatalı'); // Set error message
    }
  };

  if (!authenticated) {
    return (
      <div className="auth-container">
        <img src={process.env.PUBLIC_URL + '/images/brain.png'} alt="Description of the image" style={{width:"auto" , height:"200px"}} />
        <h2 style={{fontSize:"xx-large", marginBottom:"5px", marginTop:"10px", fontWeight:"bold"}}>İnme Merkezi Hasta Takip Sistemi</h2>
        <h1 className='signInHeader'>Lütfen Giriş Yapınız</h1>
        {/* Display error message in red color */}
        
        <input
          type="text"
          placeholder="E-Posta Adresiniz"
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Şifreniz"
          onChange={(e) => setPassword(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              authenticate(username, password);
            }
          }}
        />
        <button className="auth-button" onClick={() => authenticate(username, password)}>Giriş Yap</button>
        {/*<button className="forgot-button" onClick={forgotPassword}>Forgot Password</button}*/}
        <p style={{ color: authStatus === "Giriş yapılıyor..." ? 'green':'red' }}>{authStatus}</p>
      </div>
    );
  }

  const infoTextsRed = [
    "❗ ​Hastayı ilk 10 dk içinde muayene etmemiş",
    "❗ Hemşire kanı geç almış (BT'ye henüz gitmemiş veya gitmiş ancak acil muayenesinden 20 dk içinde değil).",
    "❗ Personel hastayı kan aldıktan sonra veya kan almadan önce 5dk içinde götürmüş ancak BT'ye yetişememiş.",
    "❗ Radyolog hasta 20 dakikadan önce teslim alınmasına rağmen hastayı geç kabul etmiş.",
    "❗ Personel 20 dakikadan sonra teslim almış (acil zamanında görmüş ve kan alınmamış veya 5dk'dan kısa sürede alınmış olmasına rağmen).",
    "❗ Nörolog geç görmüş (BT zamanında çekilmiş, kan zamanında alınmış, hasta zamanında acile dönmüş olmasına rağmen).",
    "❗ Tedavi geç başlamış (Nörolog kons zamanında, BT zamanında çekilmiş, kan zamanında alınmış olmasına rağmen).",
  ];

  const infoTextsGreen = [
    "✅​ Hastayı ilk 10 dk içinde muayene etmiş.",
    "✅​ Hemşire kanı zamanında almış.",
    "✅​ ​Personel hastayı kan aldıktan sonra veya kan almadan önce 5dk içinde götürmüş ve hasta BT'ye yetişmiş.",
    "✅​ Radyolog hasta 20 dakikadan önce teslim almış ve hastayı zamanında kabul etmiş.",
    "✅​ Personel 20 dakikadan sonra teslim almış.",
    "✅​ Nörolog zamanında görmüş.",
    "✅​ Tedavi zamanında başlamış.",
  ];
  
  const getUserName = (user_id) => {
    const user = userData.find((item) => item.user_id?.S === user_id);
    return user ? user.name?.S : '';
  };

  const getUserSurname = (user_id) => {
    const user = userData.find((item) => item.user_id?.S === user_id);
    return user ? user.surname?.S : '';
  };
  
  const getUserJob = (user_id) => {
    const user = userData.find((item) => item.user_id?.S === user_id);
    return user ? user.job?.S : '';
  };

  const getHospital = (patient_id) => {
    const patient = patientData.find((item) => item.patient_id?.S === patient_id);
    return patient ? patient.station?.S : '';
  };
  

  function sortTableByDate() {
    // Get the table body element
    const tableBody = document.querySelector('tbody');
    // Get the rows in the table body
    const rows = Array.from(tableBody.querySelectorAll('tr'));

    if(iconState){
      // Sort the rows based on the date in the second column (index 2)
      rows.sort((a, b) => {
        // Parse the date strings in "dd-mm-yy" format and convert to "mm-dd-yy"
        const datePartsA = a.children[3].textContent.split('-');
        const dateA = new Date(`${datePartsA[1]}-${datePartsA[0]}-${datePartsA[2]}`);
        
        const datePartsB = b.children[3].textContent.split('-');
        const dateB = new Date(`${datePartsB[1]}-${datePartsB[0]}-${datePartsB[2]}`);
        return dateB - dateA; // Reverse the order
      });
    
      // Clear the table body
      while (tableBody.firstChild) {
        tableBody.removeChild(tableBody.firstChild);
      }
    
      // Append the sorted rows back to the table body
      rows.forEach((row) => {
        tableBody.appendChild(row);
      });
    }

    else{
        // Sort the rows based on the date in the second column (index 2)
        rows.sort((a, b) => {
          const datePartsA = a.children[3].textContent.split('-');
          const dateA = new Date(`${datePartsA[1]}-${datePartsA[0]}-${datePartsA[2]}`);
          
          const datePartsB = b.children[3].textContent.split('-');
          const dateB = new Date(`${datePartsB[1]}-${datePartsB[0]}-${datePartsB[2]}`);
          return dateA - dateB;
        });
      
        // Clear the table body
        while (tableBody.firstChild) {
          tableBody.removeChild(tableBody.firstChild);
        }
      
        // Append the sorted rows back to the table body
        rows.forEach((row) => {
          tableBody.appendChild(row);
        });
    }
    setIconState(!iconState);
  }
  

  if (authenticated) {
    return (
      <div>
        <Navbar handleLogout={logout} name={dashboardUserName}/>
        <h2 className='rotate-screen'>Lütfen ekranı döndürünüz.</h2>
        <div className='container'>              
              <div className="upper-container">
              <div className='title-div'>
              <h2 id="patient-title">Hastaların İstasyonlarda Geçirdiği Süreler</h2>
              </div>
              <div className='togglebars'>
                <div className='toggle-container'>
                    <label className='toggle-switch'>
                      <input
                        type='checkbox'
                        onChange={showIDchange}
                      />
                      <span className='slider'></span>
                    </label>
                    {showIDs ? <p className='toggle-title'>Çalışan ID'lerini gizle </p> : <p className='toggle-title'>Çalışan ID'lerini göster</p>}
                  </div>
                  <div className='toggle-container'>
                    <label className='toggle-switch'>
                      <input
                        type='checkbox'
                        onChange={showPatientchange}
                      />
                      <span className='slider'></span>
                    </label>
                    { patientIDs ? <p className='toggle-title'>Kilitli hastaları göster </p> : <p className='toggle-title' >Kilitli hastaları gizle</p> }
                  </div>
              </div>


                <div className='excel-div'>

              <Settings acilHekimiTH = {acilHekimiTH} setAcilHekimiTH = {setAcilHekimiTH}
               kanAlmaTH = {kanAlmaTH} kanAtreshold = {setKanAlmaTH} 
               acilGörüntülemeTH = {acilGörüntülemeTH} acilGTtreshold = {setAcilGörüntülemeTH} 
               btKabulTH = {btKabulTH} BTtreshold = {setBtKabulTH}
               görüntülemeAcilTH = {görüntülemeAcilTH} görüntülemeATtreshold = {setGörüntülemeAcilTH} 
               nörologMuayeneTH = {nörologMuayeneTH} nörologMtreshold = {setnörologMuayeneTH} 
               trombolikTH = {trombolikTH} trombolitikTtreshold = {setTrombolikTH} />

              <button className="csv_button" onClick={downloadExcel}>
                  <FontAwesomeIcon className="icon" icon={faTable} /> Excel olarak indir
              </button>
              </div>

            </div>
          <div>
            <table>
              <thead>
                <tr>
                  {columnsList.map((item, index) => (
                    <th key={index} className='column'>
                      {item ===  "Tarih" ? <div>Tarih {iconState ? (
                      <FontAwesomeIcon
                        icon={faCaretUp}
                        className="fontIcon"
                        onClick={sortTableByDate}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        className="fontIcon"
                        onClick={sortTableByDate}
                      />
                    )} </div> : item}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Object.keys( patientIDs ? filterPatients(patientTimePasses,lockedPatients): patientTimePasses).map((patientId) => (
                  <tr key={patientId}>
                    <td>
                      <img className='lock' src={lockedPatients[patientId] ? process.env.PUBLIC_URL + '/images/lock.png' : process.env.PUBLIC_URL + '/images/unlock.png'} alt="Description of the image"  onClick={() => toggleLock(patientId, !lockedPatients[patientId])} />
                    </td>
                    <td>
                      <div className="dialog-trigger">
                        <div className="dialog-box" id='dialog-note'>📒 Not:<br />{patientNotes[patientId]}</div>
                        <Popup patientId={patientId}></Popup> 
                      </div>  
                    </td>
                    <td className="dialog-trigger" style={{backgroundColor:lateAssignedPatients[patientId]?"#FCFFB2":""}}>
                      <div className="dialog-box" id="dialog-barcode" style={{fontSize:"xxl"}}>
                      <Barcode value={patientId} />
                      <p style={{fontSize:"small", marginTop:"0.4em"}}>{lateAssignedPatients[patientId] ? getHospital(patientId)!== "1-Acile Kabul" ? "Bu hastanın 'Acile Kabul' işlemi henüz yapılmamıştır.": "Bu hastanın 'Acile Kabul' işlemi sonradan yapılmıştır.":""}</p>
                      </div> 
                      {patientId}</td>
                    <td>{dates[patientId]}</td>

                    {patientTimePasses[patientId].map((timePass, index) => (
                    <td key={index} className="dialog-trigger" style={getCellStyle(timePass, colorsAll[patientId][index])}>
                    {colorsAll[patientId][index] === "red" ?
                    <div className="dialog-box">
                    {infoTextsRed[index]}<br /> <br /><b>{getUserName(allUsers[patientId][index]) + "  " + getUserSurname(allUsers[patientId][index])}</b><br />{"("+getUserJob(allUsers[patientId][index])+")"}
                  </div> 
                  : colorsAll[patientId][index] === "green" ?
                      <div className="dialog-box">
                      {infoTextsGreen[index]}<br /> <br /><b>{getUserName(allUsers[patientId][index]) + "  " + getUserSurname(allUsers[patientId][index])}</b><br />{"("+getUserJob(allUsers[patientId][index])+")"} 
                   <br /> <br />{
                    } 
                    </div>
                  :
                    colorsAll[patientId][index] === "yellow" ? <div className="dialog-box">
                     ⚠️​ Daha önceki istasyonlardan en az birine kayıt yaptırılmadığından bu istasyonla ilgili bir sonuca varılmamıştır.<br /> <br /><b>{getUserName(allUsers[patientId][index]) + "  " + getUserSurname(allUsers[patientId][index])}</b><br />{"("+getUserJob(allUsers[patientId][index])+")"}
                    </div>
                  :
                    colorsAll[patientId][index] === "grey" ?  <div className="dialog-box">
                    🕒​​ Daha sonraki bir istasyonda kaydı olmasına rağmen bu istasyonda henüz kayıt yapılmamış.
                    </div>: colorsAll[patientId][index] === "orange" && <div className="dialog-box">
                    ❗​​ Bu hastanın 'Acile Giriş' saatini kontrol edin. <br /> <br /><b>{getUserName(allUsers[patientId][index]) + "  " + getUserSurname(allUsers[patientId][index])}</b><br />{"("+getUserJob(allUsers[patientId][index])+")"}
                    </div>}
                          {showIDs ? timePass !== null
                            ? String(secondsToTime(timePass)) +
                              ' (' +
                              allUsers[patientId][index] +
                              ')'
                            : 'Uğramamış'
                            : String(secondsToTime(timePass))}
                    </td>
                    ))}
                    {Array(9 - patientTimePasses[patientId].length)
                      .fill()
                      .map((_, index) => (
                        <td key={`empty-${index}`} className="dialog-trigger">
                        <div className="dialog-box">
                        🕒​​ Henüz kayıt yapılmamış.
                        </div>
                        Uğramamış</td>
                      ))}
                  </tr>
                ))}
              </tbody>
              <tfoot></tfoot>
            </table>
          </div>
          <div className='table-container2' style={{display:"none"}}>
            <table>
              <thead>
                <tr>
                  {columnsList2.map((item, index) => (
                    <th key={index} className='column'>
                      {item ===  "Tarih" ? <div>Tarih {iconState ? (
                      <FontAwesomeIcon
                        icon={faCaretUp}
                        className="fontIcon"
                        onClick={sortTableByDate}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        className="fontIcon"
                        onClick={sortTableByDate}
                      />
                    )} </div> : item}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Object.keys( patientIDs ? filterPatients(patientTimePasses,lockedPatients): patientTimePasses).map((patientId) => (
                  <tr key={patientId}>
                    <td>
                    <label className='toggle-switch'>
                      <input
                        type='checkbox'
                        checked={lockedPatients[patientId]}
                        onChange={() => toggleLock(patientId, !lockedPatients[patientId])}
                      />
                      <span className='slider'></span>
                    </label>
                    </td>
                    <td>
                      <div className="dialog-trigger">
                        <div className="dialog-box" id='dialog-note'>📒 Not:{patientNotes[patientId]}</div>
                        <Popup patientId={patientId}></Popup> 
                      </div>  
                    </td>
                    <td>{patientId}</td>
                    <td>{dates[patientId]}</td>
                    {patientTimePasses[patientId].map((timePass, index) => (
                      <React.Fragment key={index}>
                        <td className="dialog-trigger" style={getCellStyle(timePass, colorsAll[patientId][index])}>
                          {colorsAll[patientId][index] === "red" ? (
                            <div className="dialog-box">
                              {infoTextsRed[index]}<br /><br /><b>{getUserName(allUsers[patientId][index]) + "  " + getUserSurname(allUsers[patientId][index])}</b><br />{"("+getUserJob(allUsers[patientId][index])+")"}
                            </div>
                          ) : colorsAll[patientId][index] === "green" ? (
                            <div className="dialog-box">
                              {infoTextsGreen[index]}<br /><br /><b>{getUserName(allUsers[patientId][index]) + "  " + getUserSurname(allUsers[patientId][index])}</b><br />{"("+getUserJob(allUsers[patientId][index])+")"}
                            </div>
                          ) : (
                            colorsAll[patientId][index] === "yellow" && (
                              <div className="dialog-box">
                                ⚠️ Daha önceki istasyonlardan en az birine kayıt yaptırılmadığından bu istasyonla ilgili bir sonuca varılmamıştır.<br /><br /><b>{getUserName(allUsers[patientId][index]) + "  " + getUserSurname(allUsers[patientId][index])}</b><br />{"("+getUserJob(allUsers[patientId][index])+")"}
                              </div>
                            )
                          )}
                          {showIDs ? (
                            timePass !== null ? (
                              String(secondsToTime(timePass)) +
                              ' (' +
                              allUsers[patientId][index] +
                              ')'
                            ) : 'Uğramamış'
                          ) : (
                            String(secondsToTime(timePass))
                          )}
                        </td>
                        <td style={{color:"red"}}>{allUsers[patientId][index]}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                ))
                }
              </tbody>
              <tfoot></tfoot>
            </table>
          </div>
        </div>
      </div>
    );
      }
  }  

export default PatientTable;
