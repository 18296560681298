import Home from "./Home";
import PatientTable from "./PatientTable";
import Users from "./Users";
import UserTable from "./UserTable";
import ThresholdTable from "./ThresholdTable";
import Privacy from "./Privacy";
import Performances from "./Performances";
import { Amplify } from 'aws-amplify';

import awsConfig from '../aws-exports';



function App() {
  Amplify.configure(awsConfig);
  let Component
  switch (window.location.pathname) {
    case '/records':
      Component = Home;
      break;
      case '/':
        Component = PatientTable;
        break;
        case '/users':
          Component = Users;
          break;
          case '/usertable':
            Component = UserTable;
            break;
              case '/privacy':
                Component = Privacy;
                break;
                case '/performances':
                  Component = Performances;
                  break;
}
return(
  <div>
    <Component />
  </div>
)
}

export default App;