import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate,faTable, faTrash } from '@fortawesome/free-solid-svg-icons';
import Navbar from "../components/Navbar";
import { Auth } from 'aws-amplify';
import * as XLSX from 'xlsx'; 

function Users() {
  const [userData, setUserData] = useState([]);
  const [dashboardUserName, setDashboardUserName] = useState(localStorage.getItem('username'));
  const [authStatus, setAuthStatus] = useState(''); 
  const [username, setUsername] = useState([]);
  const [password, setPassword] = useState([]);
  let authenticated = localStorage.getItem('authenticated')?true:false;
  const [hospitalName, setHospitalName] = useState(dashboardUserName === "admin1" ? "Kartal Dr. Lütfi Kırdar Ş.H." : 
  dashboardUserName === "admin2" ? "İSÜ Liv Hospital Bahçeşehir" :
  dashboardUserName === "admin3" && "Başakşehir Çam ve Sakura Ş.H.");


  useEffect(() => {
    fetchUserData();
  }, []);

  const filterFetcedData = (data) => {
    let filtered_data = [];

    data.forEach(element => {
      if(element.hospital.S === hospitalName){
        filtered_data.push(element);
      }
    });
    return filtered_data;
  }

  const fetchUserData = async () => {
    try {
      const response = await axios.get('https://server.edna.com.tr/api/userData');
      let fetched_data = response.data;
      dashboardUserName != "superadmin" ? setUserData(filterFetcedData(response.data)) : setUserData(fetched_data);

    } catch (error) {
      console.error('Failed to fetch user data:', error);
    }
  };
  const refresh = () => {
    document.location.reload()
    fetchUserData();
  };

  const handleDeleteUser = async (id) => {
    const confirmed = window.confirm('Bu kullanıcıyı silmek istediğine emin misin ?');
    if (!confirmed) {
      return; // User cancelled the delete action
    }
    try {
      axios.delete(`https://server.edna.com.tr/api/data/${id}`);
      axios.delete(`https://server.edna.com.tr/api/deleteCognitoUser/${id}`);
    }catch (e) {
      window.alert(
        "Failed to delete user"
      )
      console.log("Error",e);
    }
    refresh();
  };
  


  const logout = async () => {
    try {
      await Auth.signOut();
      authenticated = false;
      localStorage.removeItem('authenticated');
      window.location.reload(true); 
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const authenticate = async (username, password) => {
    setAuthStatus('Giriş yapılıyor...');
    try {
      await Auth.signIn(username, password);
      authenticated = true;
      localStorage.setItem('authenticated', 'true'); // Store the authentication status in local storage
      localStorage.setItem('username', username);
      setDashboardUserName(localStorage.getItem('username'));
      window.location.reload(true);
    } catch (error) {
      console.error('Authentication failed:', error);
      authenticated = false;
      localStorage.removeItem('authenticated'); // Remove the authentication status from local storage
      setAuthStatus('Kullanıcı adı veya şifre hatalı'); // Set error message
    }
  };
  
  if (!authenticated) {
    return (
      <div className="auth-container">
        <img src={process.env.PUBLIC_URL + '/images/brain.png'} alt="Description of the image" style={{width:"auto" , height:"200px"}} />
        <h2 style={{fontSize:"xx-large", marginBottom:"5px", marginTop:"10px", fontWeight:"bold", textAlign:"center"}}>İnme Merkezi Hasta Takip Sistemi</h2>
        <h1 className='signInHeader'>Lütfen Giriş Yapınız</h1>
        {/* Display error message in red color */}
        
        <input
          type="text"
          placeholder="E-Posta Adresiniz"
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Şifreniz"
          onChange={(e) => setPassword(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              authenticate(username, password);
            }
          }}
        />
        <button className="auth-button" onClick={() => authenticate(username, password)}>Giriş Yap</button>
        {/*<button className="forgot-button" onClick={forgotPassword}>Forgot Password</button}*/}
        <p style={{ color: authStatus === "Giriş yapılıyor..." ? 'green':'red' }}>{authStatus}</p>
      </div>
    );
  }


  
  const downloadExcel = () => {
    const wb = XLSX.utils.table_to_book(document.querySelectorAll('table')[0]); // Convert the table to a workbook
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' }); // Write the workbook to binary format
  
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' }); // Create a Blob from binary data
    const url = URL.createObjectURL(blob);
  
    const link = document.createElement('a');
    link.href = url;
    link.download = 'Çalışan Tablosu.xlsx'; // Set the desired file name
    link.click();
  
    URL.revokeObjectURL(url);
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };
  


// Before accessing properties of user objects, check if the user object exists
  const getUserName = (user_id) => {
    const user = userData.find((item) => item.user_id?.S === user_id);
    return user ? user.name?.S : '';
  };
  
  const getUserSurname = (user_id) => {
    const user = userData.find((item) => item.user_id?.S === user_id);
    return user ? user.surname?.S : '';
  };
  
  const getUserJob = (user_id) => {
    const user = userData.find((item) => item.user_id?.S === user_id);
    return user ? user.job?.S : '';
  };

  const getUserPhoneNumber = (user_id) => {
    const user = userData.find((item) => item.user_id?.S === user_id);
    return user ? user.phoneNumber?.S : '';
  };

  const getUserDate = (user_id) => {
    const user = userData.find((item) => item.user_id?.S === user_id);
    return user ? user.date?.S : '';
  };

  const getUserTime = (user_id) => {
    const user = userData.find((item) => item.user_id?.S === user_id);
    return user ? user.time?.S : '';
  };

  const getUserHospital = (user_id) => {
    const user = userData.find((item) => item.user_id.S === user_id);
    return user ? user.hospital.S : '';
  };

  return (
    <div>
      <Navbar  handleLogout = {logout} name={dashboardUserName} />
      <div className='container'>
        <button className="csv_buton" onClick={downloadExcel}>
                <FontAwesomeIcon className="icon" icon={faTable} /> Excel olarak indir
        </button>
        <FontAwesomeIcon onClick={refresh} icon={faArrowsRotate}/>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Çalışan ID</th>
              <th>Hastane</th>
              <th>Meslek</th>
              <th>Adı</th>
              <th>Soyadı</th>
              <th>Telefon Numarası</th>
              <th>Kayıt Tarihi</th>
              <th>Kayıt Saati</th>
              <th></th> {/* Add this column for the delete button */}
            </tr>
          </thead>
          <tbody>
            {userData.map((item, index) => (
              <tr key={item.user_id}>
                <td>{index}</td>
                <td>{item.user_id.S}</td>
                <td>{getUserHospital(item.user_id.S)}</td>
                <td>{getUserJob(item.user_id.S)}</td>
                <td>{getUserName(item.user_id.S)}</td>
                <td>{getUserSurname(item.user_id.S)}</td>
                <td>{getUserPhoneNumber(item.user_id.S)}</td>
                <td>{getUserDate(item.user_id.S)}</td>
                <td>{getUserTime(item.user_id.S)}</td>
                <td>
                  <FontAwesomeIcon className="icon" onClick={() => handleDeleteUser(item.user_id.S)} icon={faTrash} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Users;