import React, { useState } from 'react';
import '../styles/SmallWindow.css'; // Import your CSS file for styling
import '../styles/Settings.css'; // Import your CSS file for styling

const Settings = (props) => {

  
  const ahmDefault = 10;
  const kanDefault = 40;
  const agtDefault = 20;
  const btDefault = 25;
  const gatDefault = 30;
  const nöroDefault = 45;
  const ttDefault = 60;

  console.log(ahmDefault, kanDefault, agtDefault);

  const [isVisible, setIsVisible] = useState(false);
  const [acilHMtreshold, setAcilHMtreshold] = useState(props.acilHekimiTH);
  const [kanAtreshold, setKanAtreshold] = useState(props.kanAlmaTH);
  const [acilGTtreshold, setAcilGTtreshold] = useState(props.acilGörüntülemeTH);
  const [BTtreshold, setBTtreshold] = useState(props.btKabulTH);
  const [görüntülemeATtreshold, setgörüntülemeATtreshold] = useState(props.görüntülemeAcilTH);
  const [nörologMtreshold, setNörologMtreshold] = useState(props.nörologMuayeneTH);
  const [trombolitikTtreshold, setTrombolitikTtreshold] = useState(props.trombolikTH);


  const handleIncrement = (setter) => {
    setter((prevValue) => prevValue + 1);
  };

  const handleDecrement = (setter) => {
    setter((prevValue) => prevValue - 1);
  };


  const toggleVisibility = () => {
    setIsVisible(true);
  };

  const toggleVisibilityClose = () => {
    setIsVisible(false);
  };

  const handleButtonClick = () => {
        props.setAcilHekimiTH(acilHMtreshold);
        props.kanAtreshold(kanAtreshold);
        props.acilGTtreshold(acilGTtreshold);
        props.BTtreshold(BTtreshold);
        props.görüntülemeATtreshold(görüntülemeATtreshold);
        props.nörologMtreshold(nörologMtreshold);
        props.trombolitikTtreshold(trombolitikTtreshold);
        //window.location.reload();
  };

  const handleInputChange = (setter, event) => {
    const value = event.target.value;
    setter(value);
  };

  const handleReset = () => {
    setAcilHMtreshold(ahmDefault);
    setKanAtreshold(kanDefault);
    setAcilGTtreshold(agtDefault);
    setBTtreshold(btDefault);
    setgörüntülemeATtreshold(gatDefault);
    setNörologMtreshold(nöroDefault);
    setTrombolitikTtreshold(ttDefault);

    props.setAcilHekimiTH(ahmDefault);
    props.kanAtreshold(kanDefault);
    props.acilGTtreshold(agtDefault);
    props.BTtreshold(btDefault);
    props.görüntülemeATtreshold(gatDefault);
    props.nörologMtreshold(nöroDefault);
    props.trombolitikTtreshold(ttDefault);
    //window.location.reload();
};

  return (
    <div className="small-window-container">
      <img  onClick={toggleVisibility} src={process.env.PUBLIC_URL + '/images/settings_icon.png'} alt="Description of the image" style={{width:"auto" , height:"2em", cursor:"pointer"}} />
      {isVisible && (
        <div className="popup">
          <div className="popup-content">
            <span className="close-button" onClick={toggleVisibilityClose}>
              &times;
            </span>
            <div className='settings-header'>
            <img src={process.env.PUBLIC_URL + '/images/settings_icon.png'} alt="Description of the image" style={{width:"auto" , height:"40px"}} />
            <h3>Eşik Değerlerini Ayarla (dk) </h3>
            </div>


            <div className="settings-input-container">
                <label>Acil Hekimi Muayene</label>
                <div className="settings-counter">
                <button onClick={() => handleDecrement(setAcilHMtreshold)}>-</button>
                <input type="text" value={acilHMtreshold} onChange={(e) => handleInputChange(setAcilHMtreshold, e)} onKeyPress={(e) => { const regex = /^[0-9]*$/; if (!regex.test(e.key)) { e.preventDefault();}}}
            />
                <button onClick={() => handleIncrement(setAcilHMtreshold)}>+</button>
                
                </div>
            </div>

            <div className="settings-input-container">
                <label>Acil-Görüntüleme Transfer</label>
                <div className="settings-counter">
                <button onClick={() => handleDecrement(setAcilGTtreshold)}>-</button>
               <input type="text" value={acilGTtreshold}  onChange={(e) => handleInputChange(setAcilGTtreshold, e)} onKeyPress={(e) => { const regex = /^[0-9]*$/; if (!regex.test(e.key)) { e.preventDefault();}}}/>
                <button onClick={() => handleIncrement(setAcilGTtreshold)}>+</button>
                </div>
            </div>
            <div className="settings-input-container">
                <label>BT Kabul</label>
                <div className="settings-counter">
                <button onClick={() => handleDecrement(setBTtreshold)}>-</button>
                <input type="text" value={BTtreshold}  onChange={(e) => handleInputChange(setBTtreshold, e)} onKeyPress={(e) => { const regex = /^[0-9]*$/; if (!regex.test(e.key)) { e.preventDefault();}}}/>
                <button onClick={() => handleIncrement(setBTtreshold)}>+</button>
                </div>
            </div>
            <div className="settings-input-container">
                <label>Görüntüleme-Acil Transfer</label>
                <div className="settings-counter">
                <button onClick={() => handleDecrement(setgörüntülemeATtreshold)}>-</button>
                <input type="text" value={görüntülemeATtreshold}  onChange={(e) => handleInputChange(setgörüntülemeATtreshold, e)} onKeyPress={(e) => { const regex = /^[0-9]*$/; if (!regex.test(e.key)) { e.preventDefault();}}}/>
                <button onClick={() => handleIncrement(setgörüntülemeATtreshold)}>+</button>
                </div>
            </div>

            <div className="settings-input-container">
                <label>Kan Alma</label>
                <div className="settings-counter">
                <button onClick={() => handleDecrement(setKanAtreshold)}>-</button>
                <input type="text" value={kanAtreshold}  onChange={(e) => handleInputChange(setKanAtreshold, e)} onKeyPress={(e) => { const regex = /^[0-9]*$/; if (!regex.test(e.key)) { e.preventDefault();}}}/>
                <button onClick={() => handleIncrement(setKanAtreshold)}>+</button>
                </div>
            </div>
            
            <div className="settings-input-container">
                <label>Nörolog Muayene</label>
                <div className="settings-counter">
                <button onClick={() => handleDecrement(setNörologMtreshold)}>-</button>
                <input type="text" value={nörologMtreshold} onChange={(e) => handleInputChange(setNörologMtreshold, e)} onKeyPress={(e) => { const regex = /^[0-9]*$/; if (!regex.test(e.key)) { e.preventDefault();}}}/>
                <button onClick={() => handleIncrement(setNörologMtreshold)}>+</button>
                </div>
            </div>
            <div className="settings-input-container">
                <label>Trombolik Tedavisi</label>
                <div className="settings-counter">
                <button onClick={() => handleDecrement(setTrombolitikTtreshold)}>-</button>
                <input type="text" value={trombolitikTtreshold}  onChange={(e) => handleInputChange(setTrombolitikTtreshold, e)} onKeyPress={(e) => { const regex = /^[0-9]*$/; if (!regex.test(e.key)) { e.preventDefault();}}}/>
                <button onClick={() => handleIncrement(setTrombolitikTtreshold)}>+</button>
                </div>
            </div>

            <div className='settings-button-container'>
            <button className="reset-button" onClick={handleReset}>Sıfırla</button>
            <div className='space'></div>
            <button className="set-button" onClick={handleButtonClick}>Değiştir</button>
            </div>

          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
