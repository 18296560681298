import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate, faTrash, faArrowRight, faTable } from '@fortawesome/free-solid-svg-icons';
import '../styles/Home.css';
import '../styles/Tables.css';
import Navbar from "../components/Navbar";
import { Auth } from 'aws-amplify';
import * as XLSX from 'xlsx'; 

function Home() {
  const [patientData, setPatientData] = useState([]);
  const [userData, setUserData] = useState([]);
  let authenticated = localStorage.getItem('authenticated')?true:false; // New state for authentication
  const [username, setUsername] = useState([]);
  const [password, setPassword] = useState([]);
  const [authStatus, setAuthStatus] = useState(''); 
  const [currentPage, setCurrentPage] = useState(1); 
  const itemsPerPage = 25; // Number of items to display per page
  const [dashboardUserName, setDashboardUserName] = useState(localStorage.getItem('username'));
  const [deletedRecords, setDeletedRecords] = useState([]);
  const [hospitalName, setHospitalName] = useState(dashboardUserName === "admin1" ? "Kartal Dr. Lütfi Kırdar Ş.H." : 
  dashboardUserName === "admin2" ? "İSÜ Liv Hospital Bahçeşehir" :
  dashboardUserName === "admin3" && "Başakşehir Çam ve Sakura Ş.H.");

  useEffect(() => {
    checkAuthentication(); 
    fetchPatientData();
    fetchUserData();
  }, []);

  const filterFetcedData = (data) => {
    let filtered_data = [];

    data.forEach(element => {
      if(element.hospital.S === hospitalName){
        filtered_data.push(element);
      }
    });
    return filtered_data;
  }

  const fetchPatientData = async () => {
    try {
      const response = await axios.get('https://server.edna.com.tr/api/patientData');
      console.log(response.data);
      let fetched_data = response.data;
      dashboardUserName != "superadmin" ? setPatientData(filterFetcedData(response.data)) : setPatientData(fetched_data);
      
    } catch (error) {
      console.error('Failed to fetch patient data:', error);
    }
  };

  const fetchUserData = async () => {
    try {
      const response = await axios.get('https://server.edna.com.tr/api/userData');
      setUserData(response.data); // Assuming the response is already in JSON format
    } catch (error) {
      console.error('Failed to fetch user data:', error);
    }
  };

  const refresh = () => {
    window.location.reload(true);
    
  }
  const handleDeleteItem =  (id, station) =>  {

    try {
      axios.delete(`https://server.edna.com.tr/api/data/${id}/${station}`);
      deletedRecords[id] = station;
      console.log(deletedRecords);
    }catch (e) {
      console.log("Error",e);
    }
    refresh();
  };
  
  

  const handleDeleteAll = async () => {
    let person = prompt("Onaylamak için 'tamam' yazıp onaylayın", "");

    if (person === "tamam") {
      try {
        await axios.delete('https://server.edna.com.tr/api/data');
        console.log('All items deleted successfully!');
        // Refresh the data after deletion
        fetchPatientData();
        alert("İşlem onaylandı. Bütün veriler sıfırlandı.");
      } catch (error) {
        console.error('Error deleting items:', error);
      }
    }
    else{
      alert("İşlem onaylanmadı.");
    }
  };

    const downloadExcel = () => {
      const wb = XLSX.utils.table_to_book(document.querySelectorAll('table')[0]); // Convert the table to a workbook
      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' }); // Write the workbook to binary format
    
      const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' }); // Create a Blob from binary data
      const url = URL.createObjectURL(blob);
    
      const link = document.createElement('a');
      link.href = url;
      link.download = 'Logs.xlsx'; // Set the desired file name
      link.click();
    
      URL.revokeObjectURL(url);
    };
  
    const s2ab = (s) => {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buf;
    };



  const authenticate = async (username, password) => {
    setAuthStatus('Giriş yapılıyor...');
    try {
      await Auth.signIn(username, password);
      authenticated = true;
      localStorage.setItem('authenticated', 'true'); // Store the authentication status in local storage
      localStorage.setItem('username', username);
      setDashboardUserName(localStorage.getItem('username'));
      window.location.reload(true);
    } catch (error) {
      console.error('Authentication failed:', error);
      authenticated = false;
      localStorage.removeItem('authenticated'); // Remove the authentication status from local storage
      setAuthStatus('Kullanıcı adı veya şifre hatalı'); // Set error message
    }
  };
  
  const checkAuthentication = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      authenticated = true;
    } catch (error) {
      console.error('Not authenticated:', error);
      authenticated = false;
    }
  };
  
  

  const logout = async () => {
    try {
      Auth.signOut();
      authenticated = false;
      localStorage.removeItem('authenticated');
      setUsername('');
      setPassword('');
      setAuthStatus('');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };
  
  if (!authenticated) {
    return (
      <div className="auth-container">
        <img src={process.env.PUBLIC_URL + '/images/brain.png'} alt="Description of the image" style={{width:"auto" , height:"200px"}} />
        <h2 style={{fontSize:"xx-large", marginBottom:"5px", marginTop:"10px", fontWeight:"bold"}}>İnme Merkezi Hasta Takip Sistemi</h2>
        <h1 className='signInHeader'>Lütfen Giriş Yapınız</h1>
        {/* Display error message in red color */}
        
        <input
          type="text"
          placeholder="E-Posta Adresiniz"
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Şifreniz"
          onChange={(e) => setPassword(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              authenticate(username, password);
            }
          }}
        />
        <button className="auth-button" onClick={() => authenticate(username, password)}>Giriş Yap</button>
        {/*<button className="forgot-button" onClick={forgotPassword}>Forgot Password</button}*/}
        <p style={{ color: authStatus === "Giriş yapılıyor..." ? 'green':'red' }}>{authStatus}</p>
      </div>
    );
  }


  // Function to get the name for a given user_id from userData
  const getUserName = (user_id) => {
    const user = userData.find((item) => item.user_id.S === user_id);
    return user ? user.name.S : '';
  };

  const getUserSurname = (user_id) => {
    const user = userData.find((item) => item.user_id.S === user_id);
    return user ? user.surname.S : '';
  };

  const getUserJob = (user_id) => {
    const user = userData.find((item) => item.user_id.S === user_id);
    return user ? user.job.S : '';
  };

  const getUserHospital = (user_id) => {
    const user = userData.find((item) => item.user_id.S === user_id);
    return user ? user.hospital.S : '';
  };

  return (
    <div>
      <Navbar  handleLogout = {logout} name={dashboardUserName}/>
      <div className="container">

      <div className='user-container'>

        <div className='title-div'>
          <h2 className='title'>Kayıtlar</h2>
        </div>
        <div className='space'></div>
        <div className='buttons-div'>
          <FontAwesomeIcon className="icon" onClick={refresh} icon={faArrowsRotate} size="x" />
          <button className="delete_all_button" onClick={handleDeleteAll}>
            Veri tabanını sıfırla
          </button>
          <button className="csv_buton" onClick={downloadExcel}>
          <FontAwesomeIcon className="icon" onClick={refresh} icon={faTable}  /> Excel olarak indir
          </button>
        </div>

      </div>
      <div className='recordtable-container'></div>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Kullanıcı ID</th>
              <th>Hastane</th>
              <th>Meslek</th>
              <th>Adı</th>
              <th>Soyadı</th>
              <th>Hasta ID</th>
              <th>İstasyon</th>
              <th>Tarih</th>
              <th>Saat</th>
              <th></th> {/* Add this column for the delete button */}
            </tr>
          </thead>
          <tbody>
          {patientData
          .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
          .map((item, index) => (
              <tr key={index}>
                <td>{index}</td>
                <td>{item.user_id.S}</td>
                <td>{getUserHospital(item.user_id.S)}</td>
                <td>{getUserJob(item.user_id.S)}</td>
                <td>{getUserName(item.user_id.S)}</td>
                <td>{getUserSurname(item.user_id.S)}</td>
                <td>{item.patient_id.S}</td>
                <td>{item.station.S}</td>
                <td>{item.date.S}</td>
                <td>{item.time.S}</td>
                <td>
                  <FontAwesomeIcon  className="icon"
                    onClick={() => handleDeleteItem(item.patient_id.S, item.station.S)}
                    icon={faTrash}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination">
            <FontAwesomeIcon className='pagination-icon' onClick={() => setCurrentPage(Math.max(currentPage - 1, 1))}
            disabled={currentPage === 1} icon={faArrowRight} rotation={180} size='l' style={{backgroundColor: "#2d3f5c",borderRadius:"10px",padding:"8px"}}/>
          <span className='pagination-number'>{currentPage}</span>
            <FontAwesomeIcon className='pagination-icon' onClick={() =>
              setCurrentPage(Math.min(currentPage + 1, Math.ceil(patientData.length / itemsPerPage)))
            }
            disabled={currentPage === Math.ceil(patientData.length / itemsPerPage)} icon={faArrowRight} size='l' style={{backgroundColor: "#2d3f5c",borderRadius:"10px",padding:"8px"}}/>
        </div>

      </div>
    </div>
  );
}

export default Home;
