import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from '../components/Navbar';
import '../styles/Tables.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp,faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { Auth } from 'aws-amplify';

function PatientTable() {
  const initialIcons = [
    { id: "icon1", isFlipped: false, name:"2-Acil Hekimi Muayene" },
    { id: "icon2", isFlipped: false, name:"3-Kan Alma" },
    { id: "icon3", isFlipped: false, name:"4-Acil - Görüntüleme Transfer" },
    { id: "icon4", isFlipped: false, name:"5-BT Kabul" },
    { id: "icon5", isFlipped: false, name:"6-Görüntüleme - Acil Transfer" },
    { id: "icon6", isFlipped: false, name:"7-Nörolog Muayene" },
    { id: "icon7", isFlipped: false, name:"8-Trombolitik Tedavisi" },
    { id: "icon8", isFlipped: false, name:"9-İAnjiyografi Kabul" },
    { id: "icon8", isFlipped: false, name:"10-İnme Merkezine Yatış" },
    // Add more icons as needed
  ];

  const [icons] = useState(initialIcons);
  const [patientData, setPatientData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [data, setData] = useState({});
  const [threshold, setThreshold] = useState(0);
  const [filter, setFilter] = useState("bigger");

  useEffect(() => {
    fetchUserData();
    fetchPatientData();
  }, []);

  useEffect(() => {
    if (patientData.length > 0 && userData.length > 0) {
      createUserTable();
    }
  }, [patientData, userData]);

  const handleThresholdChange = (event) => {
    setThreshold(event.target.value);
  };

  
  const fetchPatientData = async () => {
    try {
      const response = await axios.get('/api/patientData');
      setPatientData(response.data);
    } catch (error) {
      console.error('Failed to fetch patient data:', error);
    }
  };

  const fetchUserData = async () => {
    try {
      const response = await axios.get('/api/userData');
      setUserData(response.data);
    } catch (error) {
      console.error('Failed to fetch user data:', error);
    }
  };

  const setUsers = () => {
    const newData = {};
    for (let i = 0; i < patientData.length; i++) {
      const user_id = patientData[i]?.user_id?.S;
      if (user_id) {
        newData[user_id] = {
        
          "2-Acil Hekimi Muayene": { sum: 0, count: 0 },
          "3-Kan Alma": { sum: 0, count: 0 },
          "4-Acil - Görüntüleme Transfer": { sum: 0, count: 0 },
          "5-BT Kabul": { sum: 0, count: 0 },
          "6-Nörolog Muayene": { sum: 0, count: 0 },
          "7-Trombolitik Tedavisi": { sum: 0, count: 0 },
          "8-Anjiyografi Kabul": { sum: 0, count: 0 },
          "9-İnme Merkezine Yatış": { sum: 0, count: 0 },
        };
      }
    }
    return newData;
  };

  function changeFilter(){
    if(filter === "bigger"){
      setFilter("smaller");
    }

    else{
      setFilter("bigger");
    }
  }

  const updateUserStationData = (data, user_id, stationName, newSum) => {
    if (data?.[user_id]?.[stationName] && newSum !== 0) {
      data[user_id][stationName].sum += newSum;
      data[user_id][stationName].count += 1;
    }
  };

  function timeToSeconds(timeString) {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }

  function calculateTimePassForPatient(patientData) {
    const timePasses = {};
    
    for (let i = 1; i < patientData.length; i++) {
      const user = patientData[i].user_id.S;
      const prevStation = patientData[i - 1]?.station?.S;
      const nextStation = patientData[i]?.station?.S;
  
      let indexNext = Number(nextStation?.charAt(0));
      let indexPrev = Number(prevStation?.charAt(0));
  
      // Initialize the timePasses array for the user if it doesn't exist
      if (!timePasses[user]) {
        timePasses[user] = [];
      }
  
      if (indexNext !== i + 1) {
        for (let k = 0; k < indexNext - indexPrev - 1; k++) {
          timePasses[user].push(0);
        }
        
        const currentTime = timeToSeconds(patientData[i - 1]?.time?.S);
        const nextTime = timeToSeconds(patientData[i]?.time?.S);
        const timePass = nextTime - currentTime;
        timePasses[user].push(timePass);
      } else {
        const currentTime = timeToSeconds(patientData[i - 1]?.time?.S);
        const nextTime = timeToSeconds(patientData[i]?.time?.S);
        const timePass = nextTime - currentTime;
        timePasses[user].push(timePass);
      }
    }
  
    return timePasses;
  }
  

  function calculateTimePassForPatientCumulative(patientData) {
    const timePasses = [];
    for (let i = 1; i < patientData.length ; i++) {

      const prevStation = patientData[0]?.station?.S;
      const nextStation = patientData[i]?.station?.S;

      let indexNext = Number(nextStation?.charAt(0));
      let indexPrev = Number(prevStation?.charAt(0));

      if(indexNext !== i+1){
        for(let k=0; k<indexNext-indexPrev-1; k++){
          timePasses.push(null);
        }     
        const currentTime = timeToSeconds(patientData[0]?.time?.S);
        const nextTime = timeToSeconds(patientData[i]?.time?.S);
        const timePass = nextTime - currentTime;
        timePasses.push(timePass);
      }
      // Check if both currentStation and nextStation are defined and their first characters are the same
      else{
        const currentTime = timeToSeconds(patientData[0]?.time?.S);
        const nextTime = timeToSeconds(patientData[i]?.time?.S);
        const timePass = nextTime - currentTime;
        timePasses.push(timePass);
      }

    }
    return timePasses;
  }

  function calculateTimePassForAllPatientsCumulative(data) {
    const patients = {};
    data.forEach((entry) => {
      const patientId = entry.patient_id.S;
      if (!patients[patientId]) {
        patients[patientId] = [];
      }
      patients[patientId].push(entry);
    });

    const patientTimePasses = {};
    for (const patientId in patients) {
      patientTimePasses[patientId] = calculateTimePassForPatientCumulative(patients[patientId]);
    }
    return patientTimePasses;
  }


  function calculateTimePassForAllPatients(data) {
    const patients = {};
    data.forEach((entry) => {
      const patientId = entry.patient_id.S;
      if (!patients[patientId]) {
        patients[patientId] = [];
      }
      patients[patientId].push(entry);
    });

    const patientTimePasses = {};
    for (const patientId in patients) {
      patientTimePasses[patientId] = calculateTimePassForPatient(patients[patientId]);
    }
    console.log("AAAA",patientTimePasses)
    return patientTimePasses;
  }

  // Function to calculate time pass for all patients
  const patientTimePasses = calculateTimePassForAllPatients(patientData);
  const patientTimePassesCumulative = calculateTimePassForAllPatientsCumulative(patientData);

  async function createUserTable() {
    const station_list = {"0":"2-Acil Hekimi Muayene","1":"3-Kan Alma","2":"4-Acil - Görüntüleme Transfer","3":"5-BT Kabul","4":"6-Nörolog Muayene","5":"7-Trombolitik Tedavisi","6":"8-Anjiyografi Kabul","7":"9-İnme Merkezine Yatış"};
    let thresholdValue = parseFloat(threshold);
    console.log(patientTimePassesCumulative);
    const newData = await setUsers();
    let patient_list = [];
    for(const patient in patientTimePassesCumulative){
      const stations = patientTimePassesCumulative[patient];
      let i = 0;
      for (const station of stations) {
        if(filter === "bigger"){
          if((i <= 4 && station > thresholdValue) ){
            if(!patient_list.includes(patient))
              patient_list.push(patient);
          }
        }
        else if(filter === "smaller"){
          if((i === 4 && station < thresholdValue && station !==null) ){
            if(!patient_list.includes(patient))
              patient_list.push(patient);
          }
        }
        i++;
 
    }
  }

    for(const patient in patientTimePasses) {


      const users = patientTimePasses[patient];

      for(const user in users){
        console.log("LİST",Object.keys(users).length );
        const stations = users[user];
        console.log("asd",patient);
        for(let k=0; k<Object.keys(users).length;k++){
          
            if(patient_list.includes(patient)){
              
            for(let i = 0; i < stations.length; i++){
              
            updateUserStationData(newData, user, station_list[i], stations[i]);
            console.log(patient,"USERRR",user);          
          }
        }
      }
    }
  }
    console.log("pl",patient_list);
    console.log(patientTimePassesCumulative);
    setData(newData);
  }

  function secondsToTime(seconds) {
    if (typeof seconds === 'number') {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    } else {
      return seconds;
    }
  }
  const counts = new Array(8).fill(0);
  const calculateColumnAverage = (data) => {
    const sums = new Array(8).fill(0);
    
    Object.values(data).forEach((user) => {
      Object.keys(user).forEach((stationName, index) => {
        sums[index] += user[stationName].sum;
        counts[index] += user[stationName].count;
      });
    });
    const averages = sums.map((sum, index) => sum / counts[index]);
    return averages;
  };

  const columnAverages = calculateColumnAverage(data);

  const getCellStyle = (value, average) => {
    if (value === null) return {};
    const difference = average - value;
    const shade = Math.min(Math.abs(difference) / average, 1);
    if (difference<0) {
      return { backgroundColor: `rgba(255, 0, 0, ${shade})` };
    } else {
      return { backgroundColor: `rgba(0, 255, 0, ${shade})` };
    }
  };

  const formatAverage = (average) => {
    console.log("Average",average.toString());
    return average.toFixed(3);

  };

  // Function to handle sorting based on column attribute name
  const handleSort = (attrName,icon) => {
    // Clone the data object to avoid modifying the original state directly
    const clonedData = { ...data };
    let sortedData = "";
    if(icon.isFlipped) {
    
    // Sort the data based on the clicked attribute name
    sortedData = Object.fromEntries(
      Object.entries(clonedData).sort((a, b) => {
        const aValue = a[1][attrName]?.sum / a[1][attrName]?.count;
        const bValue = b[1][attrName]?.sum / b[1][attrName]?.count;
        return bValue - aValue;
      })
    );
    }
    else {
    // Sort the data based on the clicked attribute name
    sortedData = Object.fromEntries(
      Object.entries(clonedData).sort((a, b) => {
        const aValue = a[1][attrName]?.sum / a[1][attrName]?.count;
        const bValue = b[1][attrName]?.sum / b[1][attrName]?.count;
        return aValue - bValue;
      })
    );
    }

    icon.isFlipped = !icon.isFlipped;
    // Update the state with the sorted data
    setData(sortedData);
  };

  // Before accessing properties of user objects, check if the user object exists
  const getUserName = (user_id) => {
    const user = userData.find((item) => item.user_id?.S === user_id);
    return user ? user.name?.S : '';
  };
  const getUserSurname = (user_id) => {
    const user = userData.find((item) => item.user_id?.S === user_id);
    return user ? user.surname?.S : '';
  };

  let authenticated = localStorage.getItem('authenticated')?true:false;

  const logout = async () => {
    try {
      await Auth.signOut();
      authenticated = false;
      localStorage.removeItem('authenticated');
      window.location.reload(true);
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  if (!authenticated) {
    console.log('Authentication', authenticated);
    return (
      <div className="auth-container">
          <h1><a href='/'>Lütfen Giriş Yapınız</a></h1>
      </div>
    );
  }

  return (
    <div>
      <Navbar  handleLogout = {logout} />
      <h2>Çalışanların İstasyonlarda Geçirdiği Süreler</h2>

      {/* Step 2: Add Input Field and Apply Button */}
      <div className="threshold-container">
        <label htmlFor="thresholdInput">Sınır değer(dakika): </label>
        <input
          type="number"
          id="thresholdInput"
          value={threshold}
          onChange={handleThresholdChange}
        />
        <button onClick={changeFilter}>{filter ==="bigger"?"Daha büyük":"Daha küçük"}</button>
        <button onClick={createUserTable}>Uygula</button>
      </div>
      <div className='container'>
        <table>
          <thead>
            <tr>
              <th>Çalışan ID</th>
              <th>Adı</th>
              <th>Soyadı</th>
            {icons.map((icon) => (
          <th key={icon.id}>
            <div
            onClick={() => handleSort(icon.name,icon)}
          >
            {icon.name.slice(2,)}
            {icon.isFlipped ? (
              <FontAwesomeIcon
                icon={faCaretUp}
                className="fontIcon"
              />
            ) : (
              <FontAwesomeIcon
                icon={faCaretDown}
                className="fontIcon"
              />
            )}
          </div>
          </th>
        ))}
          </tr>
          </thead>
          <tbody>
            <tr>
              <td>Ortalama</td>
              <td></td>
              <td></td>
              {columnAverages.map((average, index) => (
                <td key={index}>{formatAverage(average)+"  (n="+counts[index]+")"}</td>
              ))}
            </tr>
            {Object.keys(data).map((patientId) => (
              <tr key={patientId}>
                <td>{patientId}</td>
                <td>{getUserName(patientId)}</td>
                <td>{getUserSurname(patientId)}</td>
                {Object.keys(data[patientId]).map((stationName, index) => (
                    <td key={index} style={getCellStyle(data[patientId][stationName].sum / data[patientId][stationName].count, columnAverages[index])}>
                    {`${data[patientId][stationName].sum > 0 ?String(secondsToTime(Number((data[patientId][stationName].sum / data[patientId][stationName].count).toFixed(0)))):"0"} + %${data[patientId][stationName].sum > 0 ?(((data[patientId][stationName].sum / data[patientId][stationName].count - columnAverages[index]) / columnAverages[index]) * 100).toFixed(2):"0"}`}
                  </td>
                ))}
                {Array(8 - Object.keys(data[patientId]).length).fill().map((_, index) => (
                  <td key={`empty-${index}`}>Veri yok</td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              {Array(8 - columnAverages.length).fill().map((_, index) => (
                <td key={`empty-avg-${index}`}></td>
              ))}
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}

export default PatientTable;
